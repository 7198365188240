<template>
  <div style="height: 100%; position: relative;">
    <div class="profile-view">
      <svg width="100%" height="100%">
        <g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
export default {
  props: {
    value: Boolean,
    bridgeType: String,
    bridgePartIndex: Number,
    bridgePartCount: Number,
    direction: String,
    startLocation: String,
    endLocation: String,
    brFlag: Number,
    bridgeComponentPartSelected: String
  },
  data: () => ({
    imgPath: {
      valueInside: false,
      sideALeft: require('@/assets/img/bridge_component_svg/side/a_left.svg'),
      sideARight: require('@/assets/img/bridge_component_svg/side/a_right.svg'),
      sideP: require('@/assets/img/bridge_component_svg/side/p.svg')
    },
    data: [],
    width: 600,
    height: 400,
    numPoints: 100,
    zoom: 0
  }),
  mounted () {
    this.valueInside = this.value
    console.log('bridge Length')
    console.log(this.bridgeLength)
    this.initZoom()
    setTimeout(() => {
      console.log(this.$refs.ooo)

      // this.$refs.ooo.style.width = '2500'
      var all = document.getElementsByClassName('profile-view')
      console.log(all)
      for (var i = 0; i < all.length; i++) {
        all[i].style.width = '100%'
      }
    }, 200)
  },
  methods: {
    handleZoom (e) {
      console.log('mount side view')
      d3.select('svg g').attr('transform', e.transform)
    },
    initZoom () {
      const self = this
      console.log('init zoom')
      const zoom = d3.zoom().on('zoom', this.handleZoom)
      const svg = d3.select('svg').call(zoom)
      d3.select('svg').on('dblclick.zoom', null)
      console.log(svg)

      const g = d3.select('g')

      console.log(this.bridgeType)
      console.log(this.bridgeComponentPartSelected)

      if (this.bridgeType === 'A') {
        if (this.bridgePartIndex.toString() === '1') {
          g.append('svg:image')
            .attr('height', 300)
            .attr('x', 500)
            .attr('y', 100)
            .attr('xlink:href', this.imgPath.sideALeft)
            .attr('class', 'hoverItem')
            .on('click', function () { self.bridgeComponentClick('A1') })
          g.append('text')
            .text('A1')
            .attr('x', 670)
            .attr('y', 300)
            .attr('class', 'textLayer')
            .attr('class', 'hoverItem')
            .on('click', function () { self.bridgeComponentClick('A1') })
        } else if (this.bridgePartIndex.toString() === '2') {
          g.append('svg:image')
            .attr('height', 300)
            .attr('x', 500)
            .attr('y', 100)
            .attr('xlink:href', this.imgPath.sideARight)
            .attr('class', 'hoverItem')
            .on('click', function () { self.bridgeComponentClick('A2') })
          g.append('text')
            .text('A2')
            .attr('x', 670)
            .attr('y', 300)
            .attr('class', 'textLayer')
            .attr('class', 'hoverItem')
            .on('click', function () { self.bridgeComponentClick('A2') })
        }
      } else if (this.bridgeType === 'P') {
        g.append('text')
          .text('P' + this.bridgePartIndex.toString())
          .attr('x', 600)
          .attr('y', 490)
          .attr('class', 'textLayer')
          .attr('class', 'hoverItem')
          .on('click', function () { self.bridgeComponentClick('P' + self.bridgePartIndex.toString()) })
        g.append('svg:image')
          .attr('height', 300)
          .attr('x', 580)
          .attr('y', 150)
          .attr('xlink:href', this.imgPath.sideP)
          .attr('class', 'hoverItem')
          .on('click', function () { self.bridgeComponentClick('P' + self.bridgePartIndex.toString()) })
      } else if (this.bridgeType === 'S') {
        g.append('rect')
          .attr('x', 400)
          .attr('y', 180)
          .attr('height', 70)
          .attr('width', 400)
          .attr('style', 'fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,0,0)')
          .attr('class', 'hoverItem')
          .on('click', function () { self.bridgeComponentClick('S' + this.bridgePartIndex.toString() + 'บน') })
        for (let i = 0; i < 2; i++) {
          for (let j = 0; j < 5; j++) {
            g.append('rect')
              .attr('x', 410 + 78 * j)
              .attr('y', 190 + 30 * i)
              .attr('height', 20)
              .attr('width', 70)
              .attr('style', 'fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,0,0)')
              .attr('class', 'hoverItem')
              .on('click', function () { self.bridgeComponentClick('S' + this.bridgePartIndex.toString() + 'บน') })
          }
        }
        g.append('rect')
          .attr('x', 400)
          .attr('y', 250)
          .attr('height', 100)
          .attr('width', 400)
          .attr('style', 'fill:rgb(226,226,226);stroke-width:1;stroke:rgb(0,0,0)')
          .attr('class', 'hoverItem')
          .on('click', function () { self.bridgeComponentClick('S' + this.bridgePartIndex.toString() + 'ล่าง') })
        g.append('text')
          .text('S' + this.bridgePartIndex.toString() + 'บน')
          .attr('x', 580)
          .attr('y', 160)
          .attr('class', 'textLayer')
          .attr('class', 'hoverItem')
          .on('click', function () { self.bridgeComponentClick('S' + this.bridgePartIndex.toString() + 'บน') })
        g.append('text')
          .text('S' + this.bridgePartIndex.toString() + 'ล่าง')
          .attr('x', 580)
          .attr('y', 380)
          .attr('class', 'textLayer')
          .attr('class', 'hoverItem')
          .on('click', function () { self.bridgeComponentClick('S' + this.bridgePartIndex.toString() + 'ล่าง') })
      } else if (this.bridgeType === 'WW') {
        if (this.bridgePartIndex.toString() === '1') {
          g.append('text')
            .text('WW' + this.bridgePartIndex.toString())
            .attr('x', 550)
            .attr('y', 450)
            .attr('class', 'textLayer')
            .attr('class', 'hoverItem')
            .on('click', function () { self.bridgeComponentClick('WW' + self.bridgePartIndex.toString()) })
          g.append('polygon')
            .attr('points', '650,100 650,400 460,400')
            .attr('style', 'fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,0,0)')
            .attr('class', 'hoverItem')
            .on('click', function () { self.bridgeComponentClick('WW' + self.bridgePartIndex.toString()) })
        } else if (this.bridgePartIndex.toString() === '2') {
          g.append('text')
            .text('WW' + this.bridgePartIndex.toString())
            .attr('x', 550)
            .attr('y', 450)
            .attr('class', 'textLayer')
            .attr('class', 'hoverItem')
            .on('click', function () { self.bridgeComponentClick('WW' + self.bridgePartIndex.toString()) })
          g.append('polygon')
            .attr('points', '460,100 650,400 460,400')
            .attr('style', 'fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,0,0)')
            .attr('class', 'hoverItem')
            .on('click', function () { self.bridgeComponentClick('WW' + self.bridgePartIndex.toString()) })
        }
      } else if (this.bridgeType === 'CV') {
        g.append('rect')
          .attr('x', 450)
          .attr('y', 150)
          .attr('height', 250)
          .attr('width', 250)
          .attr('style', 'fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,0,0)')
          .attr('class', 'hoverItem')
          .on('click', function () { self.bridgeComponentClick('CV' + this.bridgePartIndex.toString()) })
        g.append('rect')
          .attr('x', 470)
          .attr('y', 170)
          .attr('height', 210)
          .attr('width', 210)
          .attr('style', 'fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,0,0)')
          .attr('class', 'hoverItem')
          .on('click', function () { self.bridgeComponentClick('CV' + this.bridgePartIndex.toString()) })
        g.append('text')
          .text('CV' + this.bridgePartIndex.toString())
          .attr('x', 560)
          .attr('y', 270)
          .attr('class', 'textLayer')
          .attr('class', 'hoverItem')
          .on('click', function () { self.bridgeComponentClick('CV' + this.bridgePartIndex.toString()) })
        g.append('text')
          .text('S' + this.bridgePartIndex.toString() + 'T')
          .attr('x', 560)
          .attr('y', 200)
          .attr('class', 'textLayer')
          .attr('class', 'hoverItem')
          .on('click', function () { self.bridgeComponentClick('S' + this.bridgePartIndex.toString() + 'T') })
        g.append('text')
          .text('S' + this.bridgePartIndex.toString() + 'B')
          .attr('x', 560)
          .attr('y', 360)
          .attr('class', 'textLayer')
          .attr('class', 'hoverItem')
          .on('click', function () { self.bridgeComponentClick('S' + this.bridgePartIndex.toString() + 'B') })
        g.append('text')
          .text('CV' + this.bridgePartIndex.toString() + '-1')
          .attr('x', 430)
          .attr('y', 430)
          .attr('class', 'textLayer')
          .attr('class', 'hoverItem')
          .on('click', function () { self.bridgeComponentClick('CV' + this.bridgePartIndex.toString() + '-1') })
        g.append('text')
          .text('CV' + this.bridgePartIndex.toString() + '-2')
          .attr('x', 680)
          .attr('y', 430)
          .attr('class', 'textLayer')
          .attr('class', 'hoverItem')
          .on('click', function () { self.bridgeComponentClick('CV' + this.bridgePartIndex.toString() + '-2') })
      } else if (this.bridgeType === 'Box Culvert' || this.bridgeType === 'Box Underpass') {
        if (this.bridgeComponentPartSelected.charAt(0) === 'C' && this.bridgeComponentPartSelected.charAt(1) === 'V') {
          g.append('rect')
            .attr('x', 450)
            .attr('y', 150)
            .attr('height', 250)
            .attr('width', 250)
            .attr('style', 'fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,0,0)')
            .attr('class', 'hoverItem')
            .on('click', function () { self.bridgeComponentClick('CV' + this.bridgePartIndex.toString()) })
          g.append('rect')
            .attr('x', 450)
            .attr('y', 170)
            .attr('height', 210)
            .attr('width', 250)
            .attr('style', 'fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,0,0)')
            .attr('class', 'hoverItem')
            .on('click', function () { self.bridgeComponentClick('CV' + this.bridgePartIndex.toString()) })
          g.append('text')
            .text(this.bridgeComponentPartSelected)
            .attr('x', 550)
            .attr('y', 280)
            .attr('class', 'textLayer')
            .attr('class', 'hoverItem')
            .on('click', function () { self.bridgeComponentClick(this.bridgeComponentPartSelected.toString()) })
          g.append('text')
            .text('S' + this.bridgeComponentPartSelected.charAt(2).toString() + '-T')
            .attr('x', 560)
            .attr('y', 140)
            .attr('class', 'textLayer')
            .attr('class', 'hoverItem')
            .on('click', function () { self.bridgeComponentClick('S' + this.bridgePartIndex.toString() + 'T') })
          g.append('text')
            .text('S' + this.bridgeComponentPartSelected.charAt(2).toString() + '-B')
            .attr('x', 560)
            .attr('y', 420)
            .attr('class', 'textLayer')
            .attr('class', 'hoverItem')
            .on('click', function () { self.bridgeComponentClick('S' + this.bridgePartIndex.toString() + 'B') })
        } else {
          g.append('rect')
            .attr('x', 450)
            .attr('y', 150)
            .attr('height', 250)
            .attr('width', 250)
            .attr('style', 'fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,0,0)')
            .attr('class', 'hoverItem')
            .on('click', function () { self.bridgeComponentClick('CV' + this.bridgePartIndex.toString()) })
          g.append('text')
            .text(this.bridgeComponentPartSelected)
            .attr('x', 550)
            .attr('y', 280)
            .attr('class', 'textLayer')
            .attr('class', 'hoverItem')
            .on('click', function () { self.bridgeComponentClick(this.bridgeComponentPartSelected.toString()) })
        }
      }

      if (this.direction === 'right') {
        g.append('line')
          .style('stroke', 'black')
          .style('stroke-width', 3)
          .attr('x1', 400)
          .attr('y1', 50)
          .attr('x2', 800)
          .attr('y2', 50)
          .attr('marker-end', 'url(#triangle_fwd)')
      } else if (this.direction === 'left') {
        g.append('line')
          .style('stroke', 'black')
          .style('stroke-width', 3)
          .attr('x1', 400)
          .attr('y1', 50)
          .attr('x2', 800)
          .attr('y2', 50)
          .attr('marker-start', 'url(#triangle_bwd)')
      }

      g.append('text')
        .text('ทิศทางจราจร')
        .attr('x', 550)
        .attr('y', 30)
        .attr('class', 'textLayer')

      g.append('text')
        .text('กม. น้อย')
        .attr('x', 100)
        .attr('y', 275)
        .attr('class', 'textLayer')

      g.append('text')
        .text('กม. มาก')
        .attr('x', 900)
        .attr('y', 274)
        .attr('class', 'textLayer')

      g.append('text')
        .text('ไป ' + this.startLocation)
        .attr('x', 100)
        .attr('y', 210)
        .attr('class', 'textLayer')

      g.append('text')
        .text('ไป ' + this.endLocation)
        .attr('x', 900)
        .attr('y', 210)
        .attr('class', 'textLayer')

      g.append('line')
        .style('stroke', 'black')
        .style('stroke-width', 3)
        .attr('x1', 100)
        .attr('y1', 180)
        .attr('x2', 250)
        .attr('y2', 180)
        .attr('marker-start', 'url(#triangle_bwd)')

      g.append('line')
        .style('stroke', 'black')
        .style('stroke-width', 3)
        .attr('x1', 900)
        .attr('y1', 180)
        .attr('x2', 1050)
        .attr('y2', 180)
        .attr('marker-end', 'url(#triangle_fwd)')

      g.append('svg:defs').append('svg:marker')
        .attr('id', 'triangle_fwd')
        .attr('refX', 2)
        .attr('refY', 2)
        .attr('markerWidth', 30)
        .attr('markerHeight', 30)
        .attr('orient', '0deg')
        .append('path')
        .attr('d', 'M 0 0 4 2 0 4 1 2')
        .style('fill', 'black')

      g.append('svg:defs').append('svg:marker')
        .attr('id', 'triangle_bwd')
        .attr('refX', 2)
        .attr('refY', 2)
        .attr('markerWidth', 30)
        .attr('markerHeight', 30)
        .attr('orient', '180deg')
        .append('path')
        .attr('d', 'M 0 0 4 2 0 4 1 2')
        .style('fill', 'black')
    },
    bridgeComponentClick (component) {
      console.log(component)
    }
  },
  watch: {
    value () {
      this.valueInside = this.value
    },
    valueInside () {
      this.$emit('input', this.valueInside)
    }
  }
}
</script>
<style>
  .hide {
    display: none;
  }
  .hoverItem {
    cursor: pointer;
  }
</style>
<style scoped lang="scss">
  img, span {
    position: absolute
  }
  .profile-view {
    position: relative;
    height: 100%;
  }
  .spn-center {
    top: 180px;
  }
  .spn-joint {
    top: 4px;
  }
  .center.first.wing {
    left: 9px;
  }
</style>
