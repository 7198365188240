<template>
  <div style="height: 100%; position: relative">
    <div class="profile-view">
      <svg width="100%" height="100%" id="bridge">
        <g></g>
      </svg>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import { mapState } from 'vuex'
export default {
  props: {
    value: Boolean,
    bridgeType: String,
    bridgePartIndex: Number,
    bridgePartCount: Number,
    bridgePartCountMinor: Number,
    direction: String,
    startLocation: String,
    endLocation: String,
    brFlag: Number,
    isIndept: Boolean,
    indeptSectorComplete: Object,
    selectedPart: String,
    bridgePartId: Number
  },
  data: () => ({
    imgPath: {
      valueInside: false,
      bottom_a_ww_left: require('@/assets/img/bridge_component/bottom/a_ww_left.png'),
      bottom_a_ww_right: require('@/assets/img/bridge_component/bottom/a_ww_right.png'),
      bottom_br: require('@/assets/img/bridge_component_svg/bottom/br.svg'),
      bottom_slab: require('@/assets/img/bridge_component/bottom/slab.png')
    },
    data: [],
    zoom: 0,
    height: 1000,
    width: 1000,
    indeptSectorCompleteItem: {},
    lastItemId: undefined,
    lastItemColor: undefined,
    beforeHoverColor: undefined,
    beforeHoverId: undefined,
    bridgeStructure: undefined
  }),
  computed: {
    ...mapState('bridgeDetail', {
      bridgeOverview: 'bridgeOverview'
    })
  },
  mounted () {
    console.log('mount bottom')
    if (this.bridgePartId) {
      this.bridgeStructure = this.bridgeOverview.bridge_part.find(
        (bridgepart) => bridgepart[0].bridge_part_id === this.bridgePartId
      )[0].bridge_strurture_name
    }
    this.valueInside = this.value
    this.width = document.querySelector('.profile-view').clientWidth
    this.height = document.querySelector('.profile-view').clientHeight
    this.initZoom()
    setTimeout(() => {
      var all = document.getElementsByClassName('profile-view')
      for (var i = 0; i < all.length; i++) {
        all[i].style.width = '100%'
      }
    }, 200)
  },
  methods: {
    handleZoom (e) {
      d3.select('svg g').attr('transform', e.transform)
    },
    zoomFit (g) {
      const bounds = g.node().getBBox()
      const parent = g.node().parentElement
      const fullWidth = parent.clientWidth || parent.parentNode.clientWidth
      const fullHeight = parent.clientHeight || parent.parentNode.clientHeight
      const width = bounds.width + 100
      const height = bounds.height + 190
      const midX = bounds.x + width / 2
      const midY = bounds.y + height / 2
      let scale = 0.85 / Math.max(width / fullWidth, height / fullHeight)
      let translate = [
        fullWidth / 2 - scale * midX,
        fullHeight / 2 - scale * midY
      ]
      if (width === 0 || height === 0) {
        scale = 1
        translate = [0, 0]
      }
      const transform = d3.zoomIdentity
        .translate(translate[0], translate[1])
        .scale(scale)
      return transform
    },
    mouseovered (active, id) {
      return (event, d) => {
        if (active && this.lastItemId !== id) {
          // not selected
          this.beforeHoverColor = d3.select('#' + id).attr('style')
          this.beforeHoverId = id
          d3.select('#' + id).attr(
            'style',
            'fill:#eef3ff;stroke-width:1;stroke:rgb(0,0,0)'
          )
        } else if (!active && this.lastItemId !== id) {
          d3.select('#' + id).attr('style', this.beforeHoverColor)
        }
      }
    },
    initZoom () {
      console.log('init zoom')
      const self = this

      const g = d3.select('g')

      let start = 400
      let end = 1000
      let styleColor = 'fill:rgb(216,216,216);stroke-width:1;stroke:rgb(0,0,0)'
      const selectColor = 'fill:rgb(255,69,0);stroke-width:1;stroke:rgb(0,0,0)'
      if (this.bridgeType === 'A') {
        if (this.bridgePartIndex.toString() === 1) {
          g.append('svg:image')
            .attr('height', 300)
            .attr('x', 500)
            .attr('y', 100)
            .attr('xlink:href', this.imgPath.bottom_a_ww_left)
          g.append('text')
            .text('A1-WW1')
            .attr('x', 430)
            .attr('y', 120)
            .attr('class', 'textLayer')
          g.append('text')
            .text('A1-WW2')
            .attr('x', 430)
            .attr('y', 380)
            .attr('class', 'textLayer')
        } else if (this.bridgePartIndex.toString() === 2) {
          g.append('svg:image')
            .attr('height', 300)
            .attr('x', 500)
            .attr('y', 100)
            .attr('xlink:href', this.imgPath.bottom_a_ww_right)
          g.append('text')
            .text('A2-WW1')
            .attr('x', 430)
            .attr('y', 120)
            .attr('class', 'textLayer')
            .attr('class', 'hoverItem')
          g.append('text')
            .text('A2-WW2')
            .attr('x', 430)
            .attr('y', 380)
            .attr('class', 'textLayer')
            .attr('class', 'hoverItem')
        }
      } else if (this.bridgeType === 'P') {
        console.log('P')
        g.append('text')
          .text('P' + this.bridgePartIndex.toString())
          .attr('x', 590)
          .attr('y', 130)
          .attr('class', 'textLayer')
          .attr('class', 'hoverItem')
          .on('click', function () {
            self.bridgeComponentClickObject(
              'P' + self.bridgePartIndex.toString()
            )
          })
        g.append('rect')
          .attr('x', 585)
          .attr('y', 150)
          .attr('height', 500)
          .attr('width', 30)
          .attr(
            'style',
            'fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,0,0)'
          )
          .attr('class', 'hoverItem')
          .on('click', function () {
            self.bridgeComponentClickObject(
              'P' + self.bridgePartIndex.toString()
            )
          })
      } else if (
        this.bridgeType === 'BG' ||
        this.bridgeType === 'MB' ||
        this.bridgeType === 'PG'
      ) {
        let brFirst = '1'
        let brLast = '1'
        if (this.brFlag === 0) {
          brFirst = '2'
          brLast = '1'
        } else if (this.brFlag === 1) {
          brFirst = '1'
          brLast = '1'
        } else if (this.brFlag === 2) {
          brFirst = '2'
          brLast = '1'
        }
        g.append('rect')
          .attr('x', 490)
          .attr('y', 100)
          .attr('height', 50 * this.bridgePartCount)
          .attr('width', 10)
          .attr(
            'style',
            'fill:rgb(153,153,153);stroke-width:1;stroke:rgb(0,0,0)'
          )
        g.append('rect')
          .attr('x', 700)
          .attr('y', 100)
          .attr('height', 50 * this.bridgePartCount)
          .attr('width', 10)
          .attr(
            'style',
            'fill:rgb(153,153,153);stroke-width:1;stroke:rgb(0,0,0)'
          )
        for (let j = 0; j < this.bridgePartCount; j++) {
          if (this.isIndept) {
            styleColor =
              'fill:rgb(216,216,216);stroke-width:1;stroke:rgb(0,0,0)'
            for (
              let x = 0;
              x < this.indeptSectorComplete.GD.items.length;
              x++
            ) {
              if (
                (j + 1).toString() === this.indeptSectorComplete.GD.items[x]
              ) {
                styleColor = selectColor
              }
            }
          } else {
            styleColor =
              'fill:rgb(216,216,216);stroke-width:1;stroke:rgb(0,0,0)'
          }
          g.append('rect')
            .attr('id', function () {
              return `S${self.bridgePartIndex}-GD${j + 1}`
            })
            .attr('x', 500)
            .attr('y', 100 + 50 * j)
            .attr('height', 50)
            .attr('width', 200)
            .attr('style', styleColor)
            .attr('class', 'hoverItem')
            .on(
              'mouseover',
              self.mouseovered(true, `S${this.bridgePartIndex}-GD${j + 1}`)
            )
            .on(
              'mouseout',
              self.mouseovered(false, `S${this.bridgePartIndex}-GD${j + 1}`)
            )
            .on('click', function () {
              self.bridgeComponentClickObject(
                'S',
                self.bridgePartIndex,
                'GD',
                j + 1,
                `S${self.bridgePartIndex}-GD${j + 1}`,
                false,
                true
              )
              self.changeColor(`S${self.bridgePartIndex}-GD${j + 1}`)
            })
          g.append('text')
            .text(`S${this.bridgePartIndex}-GD${j + 1}`)
            .attr('x', 580)
            .attr('y', 100 + 50 * j + 30)
            .attr('class', 'textLayer')
            .attr('class', 'hoverItem')
            .on(
              'mouseover',
              self.mouseovered(true, `S${this.bridgePartIndex}-GD${j + 1}`)
            )
            .on(
              'mouseout',
              self.mouseovered(false, `S${this.bridgePartIndex}-GD${j + 1}`)
            )
            .on('click', function () {
              self.bridgeComponentClickObject(
                'S',
                self.bridgePartIndex,
                'GD',
                j + 1,
                `S${self.bridgePartIndex}-GD${j + 1}`,
                false,
                true
              )
              self.changeColor(`S${self.bridgePartIndex}-GD${j + 1}`)
            })
        }
        g.append('text')
          .text('BR' + brFirst)
          .attr('x', 480)
          .attr('y', 150 + 50 * this.bridgePartCount)
          .attr('class', 'textLayer')
        g.append('text')
          .text('BR' + brLast)
          .attr('x', 700)
          .attr('y', 150 + 50 * this.bridgePartCount)
          .attr('class', 'textLayer')
      } else if (this.bridgeType === 'IG') {
        // const itemHeight = 500 / this.bridgePartCount
        let brFirst = '1'
        let brLast = '1'
        let leftBrText = 'A1'
        let rightBrText = 'A2'
        start = 500
        end = 780 + 50 + (this.bridgePartCountMinor - 1) * 100
        console.log(start)
        if (this.brFlag === 0) {
          brFirst = '2'
          brLast = '1'
          leftBrText = 'P' + (this.bridgePartIndex - 1)
          rightBrText = 'P' + this.bridgePartIndex
        } else if (this.brFlag === 1) {
          brFirst = '1'
          brLast = '1'
          leftBrText = 'A1'
          rightBrText = 'P1'
        } else if (this.brFlag === 2) {
          brFirst = '2'
          brLast = '1'
          leftBrText = 'P' + (this.bridgePartIndex - 1)
          rightBrText = 'A2'
        }
        console.log('this.indeptSectorComplete')
        console.log(this.indeptSectorComplete)
        for (let j = 0; j < this.bridgePartCount; j++) {
          styleColor = 'fill:rgb(216,216,216);stroke-width:1;stroke:rgb(0,0,0)'
          if (this.isIndept) {
            for (
              let x = 0;
              x < this.indeptSectorComplete.GD.items.length;
              x++
            ) {
              if (
                (j + 1).toString() === this.indeptSectorComplete.GD.items[x]
              ) {
                styleColor = selectColor
              }
            }
          }
          g.append('rect')
            .attr('id', function () {
              return `S${self.bridgePartIndex}-GD${j + 1}`
            })
            .attr('x', 500)
            .attr('y', 100 + 100 * j * 2)
            .attr('height', 100)
            .attr('width', 100 + 50 + (this.bridgePartCountMinor - 1) * 100)
            .attr('style', styleColor)
            .attr('class', 'hoverItem')
            .on(
              'mouseover',
              self.mouseovered(true, `S${this.bridgePartIndex}-GD${j + 1}`)
            )
            .on(
              'mouseout',
              self.mouseovered(false, `S${this.bridgePartIndex}-GD${j + 1}`)
            )
            .on('click', function () {
              self.bridgeComponentClickObject(
                'S',
                self.bridgePartIndex,
                'GD',
                j + 1,
                `S${self.bridgePartIndex}-GD${j + 1}`,
                false,
                true
              )
              self.changeColor(`S${self.bridgePartIndex}-GD${j + 1}`)
            })
          g.append('text')
            .text(`S${this.bridgePartIndex}-GD${j + 1}`)
            .attr('x', 530 + (100 + (this.bridgePartCountMinor - 1) * 100) / 2)
            .attr('y', 103 + 100 * j * 2 + 50)
            .attr('class', 'textLayer')
            .attr('class', 'hoverItem')
            .on(
              'mouseover',
              self.mouseovered(true, `S${this.bridgePartIndex}-GD${j + 1}`)
            )
            .on(
              'mouseout',
              self.mouseovered(false, `S${this.bridgePartIndex}-GD${j + 1}`)
            )
            .on('click', function () {
              self.bridgeComponentClickObject(
                'S',
                self.bridgePartIndex,
                'GD',
                j + 1,
                `S${self.bridgePartIndex}-GD${j + 1}`,
                false,
                true
              )
              self.changeColor(`S${self.bridgePartIndex}-GD${j + 1}`)
            })
          g.append('rect')
            .attr('x', 490)
            .attr('y', 100 + 100 * j * 2)
            .attr('height', 100)
            .attr('width', 10)
            .attr(
              'style',
              'fill:rgb(112,112,112);stroke-width:2;stroke:rgb(63,63,63)'
            )
          g.append('rect')
            .attr('x', 600 + 50 + (this.bridgePartCountMinor - 1) * 100)
            .attr('y', 100 + 100 * j * 2)
            .attr('height', 100)
            .attr('width', 10)
            .attr(
              'style',
              'fill:rgb(112,112,112);stroke-width:2;stroke:rgb(63,63,63)'
            )
          g.append('text')
            .text(`${leftBrText}-${brFirst}/${j + 1}`)
            .attr('x', 400)
            .attr('y', 103 + 100 * j * 2 + 50)
            .attr('class', 'textLayer')
          g.append('text')
            .text(`${rightBrText}-${brLast}/${j + 1}`)
            .attr('x', 630 + 50 + (this.bridgePartCountMinor - 1) * 100)
            .attr('y', 103 + 100 * j * 2 + 50)
            .attr('class', 'textLayer')
          if (j !== this.bridgePartCount - 1) {
            for (let k = 1; k <= this.bridgePartCountMinor; k++) {
              styleColor =
                'fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,0,0)'
              if (this.isIndept) {
                for (
                  let x = 0;
                  x < this.indeptSectorComplete.DP.items.length;
                  x++
                ) {
                  if (
                    (j + 1).toString() + '-' + k.toString() ===
                    this.indeptSectorComplete.DP.items[x]
                  ) {
                    styleColor = selectColor
                  }
                }
              }
              g.append('rect')
                .attr('id', function () {
                  return `S${self.bridgePartIndex}-DP${j + 1}-${k}`
                })
                .attr('x', 500 + 10 + 50 + 100 * (k - 1))
                .attr('y', 100 + 100 * j * 2 + 100)
                .attr('height', 100)
                .attr('width', 30)
                .attr('style', styleColor)
                .attr('class', 'hoverItem')
                .on(
                  'mouseover',
                  self.mouseovered(
                    true,
                    `S${this.bridgePartIndex}-DP${j + 1}-${k}`
                  )
                )
                .on(
                  'mouseout',
                  self.mouseovered(
                    false,
                    `S${this.bridgePartIndex}-DP${j + 1}-${k}`
                  )
                )
                .on('click', function () {
                  self.bridgeComponentClickObject(
                    'S',
                    self.bridgePartIndex,
                    'DP',
                    `${j + 1}-${k}`,
                    `S${self.bridgePartIndex}-DP${j + 1}-${k}`,
                    false,
                    true
                  )
                  self.changeColor(`S${self.bridgePartIndex}-DP${j + 1}-${k}`)
                })
              styleColor =
                'fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,0,0)'
              if (this.isIndept) {
                for (
                  let x = 0;
                  x < this.indeptSectorComplete.DK.items.length;
                  x++
                ) {
                  if (
                    (j + 1).toString() + '-' + k.toString() ===
                    this.indeptSectorComplete.DK.items[x]
                  ) {
                    styleColor = selectColor
                  }
                }
              }
              g.append('rect')
                .attr('id', function () {
                  return `S${self.bridgePartIndex}-DK${j + 1}-${k}`
                })
                .attr('x', 500 + 10 + 50 + 100 * (k - 1) - 70)
                .attr('y', 100 + 100 * j * 2 + 100)
                .attr('height', 100)
                .attr('width', 70)
                .attr('style', styleColor)
                .attr('class', 'hoverItem')
                .on(
                  'mouseover',
                  self.mouseovered(
                    true,
                    `S${this.bridgePartIndex}-DK${j + 1}-${k}`
                  )
                )
                .on(
                  'mouseout',
                  self.mouseovered(
                    false,
                    `S${this.bridgePartIndex}-DK${j + 1}-${k}`
                  )
                )
                .on('click', function () {
                  if (self.bridgeStructure === 'Slab Type') {
                    self.bridgeComponentClickObject(
                      'S',
                      self.bridgePartIndex,
                      'GD',
                      `${j + 1}-${k}`,
                      `S${self.bridgePartIndex}-GD${j + 1}-${k}`,
                      false,
                      true
                    )
                  } else self.bridgeComponentClickObject('S', self.bridgePartIndex, 'DK', `${j + 1}-${k}`, `S${self.bridgePartIndex}-DK${j + 1}-${k}`, false, true)
                  self.changeColor(`S${self.bridgePartIndex}-DK${j + 1}-${k}`)
                })
              g.append('text')
                .text(`S${this.bridgePartIndex}-DP${j + 1}-${k}`)
                .attr('y', 500 + 10 + 50 + 100 * (k - 1) - 40)
                .attr('x', -(103 + 100 * j * 2 + 50 + 75))
                // .attr('transform', 'translate(500 + 10 + 50 + 100 * (' + k.toString() + ' - 1) - 20, 103 + (50 * ' + j.toString() + ' * 2) + 27 + 50) rotate(-90)')
                .attr('transform', 'translate(60, 60) rotate(-90)')
                .attr('class', 'textLayer')
                .attr('class', 'hoverItem')
                .on(
                  'mouseover',
                  self.mouseovered(
                    true,
                    `S${this.bridgePartIndex}-DP${j + 1}-${k}`
                  )
                )
                .on(
                  'mouseout',
                  self.mouseovered(
                    false,
                    `S${this.bridgePartIndex}-DP${j + 1}-${k}`
                  )
                )
                .on('click', function () {
                  self.bridgeComponentClickObject(
                    'S',
                    self.bridgePartIndex,
                    'DP',
                    `${j + 1}-${k}`,
                    `S${self.bridgePartIndex}-DP${j + 1}-${k}`,
                    false,
                    true
                  )
                  self.changeColor(`S${self.bridgePartIndex}-DP${j + 1}-${k}`)
                })
              g.append('text')
                .text(`S${this.bridgePartIndex}-DK${j + 1}-${k}`)
                .attr('y', 500 + 10 + 50 + 100 * (k - 1) - 85)
                .attr('x', -(103 + 100 * j * 2 + 50 + 75))
                .attr('transform', 'translate(60, 60) rotate(-90)')
                .attr('class', 'textLayer')
                .attr('class', 'hoverItem')
                .on(
                  'mouseover',
                  self.mouseovered(
                    true,
                    `S${this.bridgePartIndex}-DK${j + 1}-${k}`
                  )
                )
                .on(
                  'mouseout',
                  self.mouseovered(
                    false,
                    `S${this.bridgePartIndex}-DK${j + 1}-${k}`
                  )
                )
                .on('click', function () {
                  if (self.bridgeStructure === 'Slab Type') {
                    self.bridgeComponentClickObject(
                      'S',
                      self.bridgePartIndex,
                      'GD',
                      `${j + 1}-${k}`,
                      `S${self.bridgePartIndex}-GD${j + 1}-${k}`,
                      false,
                      true
                    )
                  } else self.bridgeComponentClickObject('S', self.bridgePartIndex, 'DK', `${j + 1}-${k}`, `S${self.bridgePartIndex}-DK${j + 1}-${k}`, false, true)
                  self.changeColor(`S${self.bridgePartIndex}-DK${j + 1}-${k}`)
                })
            }
            styleColor =
              'fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,0,0)'
            if (this.isIndept) {
              for (
                let x = 0;
                x < this.indeptSectorComplete.DK.items.length;
                x++
              ) {
                if (
                  (j + 1).toString() +
                    '-' +
                    (self.bridgePartCountMinor + 1).toString() ===
                  this.indeptSectorComplete.DK.items[x]
                ) {
                  styleColor = selectColor
                }
              }
            }
            g.append('rect')
              .attr('id', function () {
                return `S${
                  self.bridgePartIndex
                }-DK${j + 1}-${self.bridgePartCountMinor + 1}`
              })
              .attr(
                'x',
                500 + 10 + 50 + 100 * (this.bridgePartCountMinor + 1 - 1) - 70
              )
              .attr('y', 100 + 100 * j * 2 + 100)
              .attr('height', 100)
              .attr('width', 70)
              .attr('style', styleColor)
              .attr('class', 'hoverItem')
              .on(
                'mouseover',
                self.mouseovered(
                  true,
                  `S${this.bridgePartIndex}-DK${j + 1}-${
                    this.bridgePartCountMinor + 1
                  }`
                )
              )
              .on(
                'mouseout',
                self.mouseovered(
                  false,
                  `S${this.bridgePartIndex}-DK${j + 1}-${
                    this.bridgePartCountMinor + 1
                  }`
                )
              )
              .on('click', function () {
                if (self.bridgeStructure === 'Slab Type') {
                  self.bridgeComponentClickObject(
                    'S',
                    self.bridgePartIndex,
                    'GD',
                    `${j + 1}-${self.bridgePartCountMinor + 1}`,
                    `S${self.bridgePartIndex}-GD${j + 1}-${
                      self.bridgePartCountMinor + 1
                    }`,
                    false,
                    true
                  )
                } else self.bridgeComponentClickObject('S', self.bridgePartIndex, 'DK', `${j + 1}-${self.bridgePartCountMinor + 1}`, `S${self.bridgePartIndex}-DK${j + 1}-${self.bridgePartCountMinor + 1}`, false, true)
                self.changeColor(
                  `S${self.bridgePartIndex}-DK${j + 1}-${
                    self.bridgePartCountMinor + 1
                  }`
                )
              })
            g.append('text')
              .text(
                `S${this.bridgePartIndex}-DK${j + 1}-${
                  this.bridgePartCountMinor + 1
                }`
              )
              .attr(
                'y',
                500 + 10 + 50 + 100 * (this.bridgePartCountMinor + 1 - 1) - 85
              )
              .attr('x', -(103 + 100 * j * 2 + 50 + 75))
              .attr('transform', 'translate(60, 60) rotate(-90)')
              .attr('class', 'textLayer')
              .attr('class', 'hoverItem')
              .on(
                'mouseover',
                self.mouseovered(
                  true,
                  `S${this.bridgePartIndex}-DK${j + 1}-${
                    this.bridgePartCountMinor + 1
                  }`
                )
              )
              .on(
                'mouseout',
                self.mouseovered(
                  false,
                  `S${this.bridgePartIndex}-DK${j + 1}-${
                    this.bridgePartCountMinor + 1
                  }`
                )
              )
              .on('click', function () {
                if (self.bridgeStructure === 'Slab Type') {
                  self.bridgeComponentClickObject(
                    'S',
                    self.bridgePartIndex,
                    'GD',
                    `${j + 1}-${self.bridgePartCountMinor + 1}`,
                    `S${self.bridgePartIndex}-GD${j + 1}-${
                      self.bridgePartCountMinor + 1
                    }`,
                    false,
                    true
                  )
                } else self.bridgeComponentClickObject('S', self.bridgePartIndex, 'DK', `${j + 1}-${self.bridgePartCountMinor + 1}`, `S${self.bridgePartIndex}-DK${j + 1}-${self.bridgePartCountMinor + 1}`, false, true)
                self.changeColor(
                  `S${self.bridgePartIndex}-DK${j + 1}-${
                    self.bridgePartCountMinor + 1
                  }`
                )
              })
          }
        }
        g.append('text')
          .text('BR' + brFirst)
          .attr('x', 480)
          .attr('y', 150 + 100 * this.bridgePartCount * 2 - 100)
          .attr('class', 'textLayer')
        g.append('text')
          .text('BR' + brLast)
          .attr('x', 600 + 50 + (this.bridgePartCountMinor - 1) * 100)
          .attr('y', 150 + 100 * this.bridgePartCount * 2 - 100)
          .attr('class', 'textLayer')
      } else if (this.bridgeType === 'ST') {
        let brFirst = '1'
        let brLast = '1'
        if (this.brFlag === 0) {
          brFirst = '2'
          brLast = '1'
        } else if (this.brFlag === 1) {
          brFirst = '1'
          brLast = '1'
        } else if (this.brFlag === 2) {
          brFirst = '2'
          brLast = '1'
        }
        g.append('svg:image')
          .attr('height', 506)
          .attr('x', 400 - 3)
          .attr('y', 97)
          .attr('xlink:href', this.imgPath.bottom_br)
        // .attr('transform', 'rotate(180, 500, ' + '0' + ')')
        g.append('svg:image')
          .attr('height', 506)
          .attr('x', 400 + 400 + 13)
          .attr('y', 97)
          .attr('xlink:href', this.imgPath.bottom_br)
        if (this.isIndept) {
          styleColor = 'fill:rgb(216,216,216);stroke-width:1;stroke:rgb(0,0,0)'
          if (this.indeptSectorComplete.DK.items.length > 0) {
            styleColor = selectColor
          }
        } else {
          styleColor = 'fill:rgb(216,216,216);stroke-width:1;stroke:rgb(0,0,0)'
        }
        g.append('rect')
          .attr('id', function () {
            return 'S' + self.bridgePartIndex.toString()
          })
          .attr('x', 15 + 400)
          .attr('y', 100)
          .attr('height', 500)
          .attr('width', 400)
          .attr('style', styleColor)
          .attr('class', 'hoverItem')
          .on('mouseover', self.mouseovered(true, `S${this.bridgePartIndex}`))
          .on('mouseout', self.mouseovered(false, `S${this.bridgePartIndex}`))
          .on('click', function () {
            if (self.bridgeStructure === 'Slab Type') {
              self.bridgeComponentClickObject(
                'S',
                self.bridgePartIndex,
                'GD',
                self.bridgePartIndex,
                'S' + self.bridgePartIndex.toString(),
                false,
                true
              )
            } else self.bridgeComponentClickObject('S', self.bridgePartIndex, 'DK', self.bridgePartIndex, 'S' + self.bridgePartIndex.toString(), false, true)
            self.changeColor('S' + self.bridgePartIndex.toString())
          })
        g.append('text')
          .text('S' + this.bridgePartIndex.toString())
          .attr('x', 600)
          .attr('y', 350)
          .attr('class', 'textLayer')
          .attr('class', 'hoverItem')
          .on('mouseover', self.mouseovered(true, `S${this.bridgePartIndex}`))
          .on('mouseout', self.mouseovered(false, `S${this.bridgePartIndex}`))
          .on('click', function () {
            if (self.bridgeStructure === 'Slab Type') {
              self.bridgeComponentClickObject(
                'S',
                self.bridgePartIndex,
                'GD',
                self.bridgePartIndex,
                'S' + self.bridgePartIndex.toString(),
                false,
                true
              )
            } else self.bridgeComponentClickObject('S', self.bridgePartIndex, 'DK', self.bridgePartIndex, 'S' + self.bridgePartIndex.toString(), false, true)
            self.changeColor('S' + self.bridgePartIndex.toString())
          })
        g.append('text')
          .text('BR' + brFirst)
          .attr('x', 400)
          .attr('y', 630)
          .attr('class', 'textLayer')
        g.append('text')
          .text('BR' + brLast)
          .attr('x', 800 + 15.65)
          .attr('y', 630)
          .attr('class', 'textLayer')
      } else if (this.bridgeType === 'SG') {
        // const itemHeight = 400 / this.bridgePartCount
        end = 600 + this.bridgePartCount * 50
        let brFirst = '1'
        let brLast = '1'
        let leftBrText = 'A1'
        let rightBrText = 'A2'
        if (this.brFlag === 0) {
          brFirst = '2'
          brLast = '1'
          leftBrText = 'P' + (this.bridgePartIndex - 1)
          rightBrText = 'P' + this.bridgePartIndex
        } else if (this.brFlag === 1) {
          brFirst = '1'
          brLast = '1'
          leftBrText = 'A1'
          rightBrText = 'P1'
        } else if (this.brFlag === 2) {
          brFirst = '2'
          brLast = '1'
          leftBrText = 'P' + (this.bridgePartIndex - 1)
          rightBrText = 'A2'
        }
        for (let j = 0; j < this.bridgePartCount; j++) {
          if (this.isIndept) {
            styleColor =
              'fill:rgb(216,216,216);stroke-width:1;stroke:rgb(0,0,0)'
            for (
              let x = 0;
              x < this.indeptSectorComplete.GD.items.length;
              x++
            ) {
              if (
                (j + 1).toString() === this.indeptSectorComplete.GD.items[x]
              ) {
                styleColor = selectColor
              }
            }
          } else {
            styleColor =
              'fill:rgb(216,216,216);stroke-width:1;stroke:rgb(0,0,0)'
          }
          g.append('rect')
            .attr('id', function () {
              return `S${self.bridgePartIndex}-GD${j + 1}`
            })
            .attr('x', 400 + 50 * j)
            .attr('y', 100)
            .attr('height', 500)
            .attr('width', 50)
            .attr('style', styleColor)
            .attr('class', 'hoverItem')
            .on(
              'mouseover',
              self.mouseovered(true, `S${this.bridgePartIndex}-GD${j + 1}`)
            )
            .on(
              'mouseout',
              self.mouseovered(false, `S${this.bridgePartIndex}-GD${j + 1}`)
            )
            .on('click', function () {
              self.bridgeComponentClickObject(
                'S',
                self.bridgePartIndex,
                'GD',
                j + 1,
                `S${self.bridgePartIndex}-GD${j + 1}`,
                false,
                true
              )
              self.changeColor(`S${self.bridgePartIndex}-GD${j + 1}`)
            })
        }
        for (let j = 0; j < this.bridgePartCount; j++) {
          g.append('text')
            .text(`S${this.bridgePartIndex}-GD${j + 1}`)
            .attr('x', -400)
            .attr('y', 430 + 50 * j)
            .attr('transform', 'rotate(270)')
            .attr('class', 'textLayer')
            .attr('class', 'hoverItem')
            .on(
              'mouseover',
              self.mouseovered(true, `S${this.bridgePartIndex}-GD${j + 1}`)
            )
            .on(
              'mouseout',
              self.mouseovered(false, `S${this.bridgePartIndex}-GD${j + 1}`)
            )
            .on('click', function () {
              self.bridgeComponentClickObject(
                'S',
                self.bridgePartIndex,
                'GD',
                j + 1,
                `S${self.bridgePartIndex}-GD${j + 1}`,
                false,
                true
              )
              self.changeColor(`S${self.bridgePartIndex}-GD${j + 1}`)
            })
        }
        g.append('text')
          .text('S' + self.bridgePartIndex.toString())
          .attr('x', 400 + (this.bridgePartCount * 50) / 2)
          .attr('y', 650)
          .attr('class', 'textLayer')
        g.append('rect')
          .attr('x', 400)
          .attr('y', 180)
          .attr('height', 30)
          .attr('width', 10)
          .attr(
            'style',
            'fill:rgb(112,112,112);stroke-width:2;stroke:rgb(63,63,63)'
          )
        g.append('text')
          .text(leftBrText + '-BR' + brFirst + '/1')
          .attr('x', 320)
          .attr('y', 200)
          .attr('class', 'textLayer')
        g.append('rect')
          .attr('x', 400)
          .attr('y', 250)
          .attr('height', 30)
          .attr('width', 10)
          .attr(
            'style',
            'fill:rgb(112,112,112);stroke-width:2;stroke:rgb(63,63,63)'
          )
        g.append('text')
          .text(leftBrText + '-BR' + brFirst + '/2')
          .attr('x', 320)
          .attr('y', 270)
          .attr('class', 'textLayer')
        g.append('rect')
          .attr('x', 400)
          .attr('y', 450)
          .attr('height', 30)
          .attr('width', 10)
          .attr(
            'style',
            'fill:rgb(112,112,112);stroke-width:2;stroke:rgb(63,63,63)'
          )
        g.append('text')
          .text(leftBrText + '-BR' + brFirst + '/3')
          .attr('x', 320)
          .attr('y', 470)
          .attr('class', 'textLayer')
        g.append('rect')
          .attr('x', 400)
          .attr('y', 520)
          .attr('height', 30)
          .attr('width', 10)
          .attr(
            'style',
            'fill:rgb(112,112,112);stroke-width:2;stroke:rgb(63,63,63)'
          )
        g.append('text')
          .text(leftBrText + '-BR' + brFirst + '/4')
          .attr('x', 320)
          .attr('y', 540)
          .attr('class', 'textLayer')
        g.append('rect')
          .attr('x', 400 - 10 + this.bridgePartCount * 50)
          .attr('y', 180)
          .attr('height', 30)
          .attr('width', 10)
          .attr(
            'style',
            'fill:rgb(112,112,112);stroke-width:2;stroke:rgb(63,63,63)'
          )
        g.append('text')
          .text(rightBrText + '-BR' + brLast + '/1')
          .attr('x', 400 + 10 + this.bridgePartCount * 50)
          .attr('y', 200)
          .attr('class', 'textLayer')
        g.append('rect')
          .attr('x', 400 - 10 + this.bridgePartCount * 50)
          .attr('y', 250)
          .attr('height', 30)
          .attr('width', 10)
          .attr(
            'style',
            'fill:rgb(112,112,112);stroke-width:2;stroke:rgb(63,63,63)'
          )
        g.append('text')
          .text(rightBrText + '-BR' + brLast + '/2')
          .attr('x', 400 + 10 + this.bridgePartCount * 50)
          .attr('y', 270)
          .attr('class', 'textLayer')
        g.append('rect')
          .attr('x', 400 - 10 + this.bridgePartCount * 50)
          .attr('y', 450)
          .attr('height', 30)
          .attr('width', 10)
          .attr(
            'style',
            'fill:rgb(112,112,112);stroke-width:2;stroke:rgb(63,63,63)'
          )
        g.append('text')
          .text(rightBrText + '-BR' + brLast + '/3')
          .attr('x', 400 + 10 + this.bridgePartCount * 50)
          .attr('y', 470)
          .attr('class', 'textLayer')
        g.append('rect')
          .attr('x', 400 - 10 + this.bridgePartCount * 50)
          .attr('y', 520)
          .attr('height', 30)
          .attr('width', 10)
          .attr(
            'style',
            'fill:rgb(112,112,112);stroke-width:2;stroke:rgb(63,63,63)'
          )
        g.append('text')
          .text(rightBrText + '-BR' + brLast + '/4')
          .attr('x', 400 + 10 + this.bridgePartCount * 50)
          .attr('y', 540)
          .attr('class', 'textLayer')
      } else if (this.bridgeType === 'UG') {
        // const itemHeight = 500 / this.bridgePartCount
        let brFirst = 'BR1'
        let brLast = 'BR1'
        let leftBrText = 'A1'
        let rightBrText = 'A2'
        if (this.brFlag === 0) {
          brFirst = 'BR2'
          brLast = 'BR1'
          leftBrText = 'P' + (this.bridgePartIndex - 1)
          rightBrText = 'P' + this.bridgePartIndex
        } else if (this.brFlag === 1) {
          brFirst = 'BR1'
          brLast = 'BR1'
          leftBrText = 'A1'
          rightBrText = 'P1'
        } else if (this.brFlag === 2) {
          brFirst = 'BR2'
          brLast = 'BR1'
          leftBrText = 'P' + (this.bridgePartIndex - 1)
          rightBrText = 'A2'
        }
        for (let j = 0; j < this.bridgePartCount; j++) {
          if (j === 0) {
            g.append('rect')
              .attr('x', 15.65 + 500)
              .attr('y', 100 + 50 * j)
              .attr('height', 50 / 5)
              .attr('width', 200)
              .attr(
                'style',
                'fill:rgb(216,216,216);stroke-width:1;stroke:rgb(0,0,0)'
              )
          }
          g.append('rect')
            .attr('x', 15.65 + 500)
            .attr('y', 100 + 50 * j + 50 / 5)
            .attr('height', 50 / 10)
            .attr('width', 200)
            .attr(
              'style',
              'fill:rgb(216,216,216);stroke-width:1;stroke:rgb(0,0,0)'
            )
          g.append('rect')
            .attr('id', function () {
              return (
                'S' +
                self.bridgePartIndex.toString() +
                '-GD' +
                (j + 1).toString()
              )
            })
            .attr('x', 15.65 + 500)
            .attr('y', 100 + 50 * j + (50 / 10) * 3)
            .attr('height', (50 / 10) * 7)
            .attr('width', 200)
            .attr(
              'style',
              'fill:url(#diagonalHatch);stroke-width:1;stroke:rgb(0,0,0);'
            )
            .attr('class', 'hoverItem')
            .on('click', function () {
              self.bridgeComponentClickObject(
                'S',
                self.bridgePartIndex,
                'GD',
                j + 1,
                'S' +
                  self.bridgePartIndex.toString() +
                  '-GD' +
                  (j + 1).toString(),
                false,
                true
              )
              self.changeColor(
                'S' +
                  self.bridgePartIndex.toString() +
                  '-GD' +
                  (j + 1).toString()
              )
            })
          g.append('rect')
            .attr('x', 15.65 + 500)
            .attr('y', 100 + 50 * j + (50 / 10) * 3)
            .attr('height', (50 / 10) * 7)
            .attr('width', 10)
            .attr(
              'style',
              'fill:rgb(112,112,112);stroke-width:1;stroke:rgb(0,0,0);'
            )
          g.append('rect')
            .attr('x', 5.65 + 700)
            .attr('y', 100 + 50 * j + (50 / 10) * 3)
            .attr('height', (50 / 10) * 7)
            .attr('width', 10)
            .attr(
              'style',
              'fill:rgb(112,112,112);stroke-width:1;stroke:rgb(0,0,0);'
            )
          g.append('rect')
            .attr('x', 15.65 + 500)
            .attr('y', 100 + 50 * j + (50 / 10) * 3 + ((50 / 10) * 7) / 5)
            .attr('height', ((50 / 10) * 7) / 7)
            .attr('width', 10)
            .attr(
              'style',
              'fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,0,0);'
            )
          g.append('rect')
            .attr('x', 5.65 + 700)
            .attr('y', 100 + 50 * j + (50 / 10) * 3 + ((50 / 10) * 7) / 5)
            .attr('height', ((50 / 10) * 7) / 7)
            .attr('width', 10)
            .attr(
              'style',
              'fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,0,0);'
            )
          g.append('text')
            .text(leftBrText + '-' + brFirst + '/' + (j + 1).toString())
            .attr('x', 420)
            .attr('y', 108 + 50 * j + (50 / 10) * 3 + ((50 / 10) * 7) / 5)
            .attr('class', 'textLayer')
          g.append('rect')
            .attr('x', 15.65 + 500)
            .attr('y', 100 + 50 * j + (50 / 10) * 7)
            .attr('height', 50 / 10)
            .attr('width', 200)
            .attr(
              'style',
              'fill:rgb(216,216,216);stroke-width:1;stroke:rgb(0,0,0)'
            )
          g.append('text')
            .text(rightBrText + '-' + brLast + '/' + (j + 1).toString())
            .attr('x', 730)
            .attr('y', 108 + 50 * j + (50 / 10) * 3 + ((50 / 10) * 7) / 5)
            .attr('class', 'textLayer')
          if (j !== this.bridgePartCount - 1) {
            g.append('rect')
              .attr('x', 15.65 + 500)
              .attr('y', 100 + 50 * j + (50 / 10) * 8)
              .attr('height', (50 / 5) * 2)
              .attr('width', 200)
              .attr(
                'style',
                'fill:rgb(216,216,216);stroke-width:1;stroke:rgb(0,0,0)'
              )
          } else {
            g.append('rect')
              .attr('x', 15.65 + 500)
              .attr('y', 100 + 50 * j + (50 / 10) * 8)
              .attr('height', 50 / 5)
              .attr('width', 200)
              .attr(
                'style',
                'fill:rgb(216,216,216);stroke-width:1;stroke:rgb(0,0,0)'
              )
          }
          g.append('text')
            .text(`S${this.bridgePartIndex}-GD${j + 1}`)
            .attr('x', 590)
            .attr('y', 105 + 50 * j + 50 / 2)
            .attr('class', 'textLayer')
            .attr('class', 'hoverItem')
            .on('click', function () {
              self.bridgeComponentClickObject(
                'S',
                self.bridgePartIndex,
                'GD',
                j + 1,
                `S${self.bridgePartIndex}-GD${j + 1}`,
                false,
                true
              )
              self.changeColor(`S${self.bridgePartIndex}-GD${j + 1}`)
            })
        }
        g.append('text')
          .text(brFirst)
          .attr('x', 500)
          .attr('y', 150 + 50 * this.bridgePartCount)
          .attr('class', 'textLayer')
        g.append('text')
          .text(brLast)
          .attr('x', 700 + 15.65)
          .attr('y', 150 + 50 * this.bridgePartCount)
          .attr('class', 'textLayer')
      }

      if (this.direction === 'right') {
        g.append('line')
          .style('stroke', 'black')
          .style('stroke-width', 3)
          .attr('x1', start)
          .attr('y1', 50)
          .attr('x2', end - 200)
          .attr('y2', 50)
          .attr('marker-end', 'url(#triangle_fwd)')
      } else if (this.direction === 'left') {
        g.append('line')
          .style('stroke', 'black')
          .style('stroke-width', 3)
          .attr('x1', start)
          .attr('y1', 50)
          .attr('x2', end - 200)
          .attr('y2', 50)
          .attr('marker-start', 'url(#triangle_bwd)')
      }

      g.append('text')
        .text('ทิศทางจราจร')
        .attr('x', (start + end - 400) / 2)
        .attr('y', 30)
        .attr('class', 'textLayer')

      g.append('text')
        .text('กม. น้อย')
        .attr('x', 100)
        .attr('y', 275)
        .attr('class', 'textLayer')

      g.append('text')
        .text('กม. มาก')
        .attr('x', end)
        .attr('y', 274)
        .attr('class', 'textLayer')

      g.append('text')
        .text('ไป ' + this.startLocation)
        .attr('x', 100)
        .attr('y', 210)
        .attr('class', 'textLayer')

      g.append('text')
        .text('ไป ' + this.endLocation)
        .attr('x', end)
        .attr('y', 210)
        .attr('class', 'textLayer')

      g.append('line')
        .style('stroke', 'black')
        .style('stroke-width', 3)
        .attr('x1', 100)
        .attr('y1', 180)
        .attr('x2', 250)
        .attr('y2', 180)
        .attr('marker-start', 'url(#triangle_bwd)')

      g.append('line')
        .style('stroke', 'black')
        .style('stroke-width', 3)
        .attr('x1', end)
        .attr('y1', 180)
        .attr('x2', end + 150)
        .attr('y2', 180)
        .attr('marker-end', 'url(#triangle_fwd)')

      g.append('svg:defs')
        .append('svg:marker')
        .attr('id', 'triangle_fwd')
        .attr('refX', 2)
        .attr('refY', 2)
        .attr('markerWidth', 30)
        .attr('markerHeight', 30)
        .attr('orient', '0deg')
        .append('path')
        .attr('d', 'M 0 0 4 2 0 4 1 2')
        .style('fill', 'black')

      g.append('svg:defs')
        .append('svg:marker')
        .attr('id', 'triangle_bwd')
        .attr('refX', 2)
        .attr('refY', 2)
        .attr('markerWidth', 30)
        .attr('markerHeight', 30)
        .attr('orient', '180deg')
        .append('path')
        .attr('d', 'M 0 0 4 2 0 4 1 2')
        .style('fill', 'black')

      g.append('svg:defs')
        .append('svg:pattern')
        .attr('id', 'diagonalHatch')
        .attr('patternUnits', 'userSpaceOnUse')
        .attr('patternTransform', 'rotate(45)')
        .attr('width', 9.5)
        .attr('height', 9.5)
        .append('line')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', 9.5)
        .attr('stroke-width', '1')
        .style('stroke', 'black')

      const zoom = d3.zoom().scaleExtent([0.05, 5]).on('zoom', this.handleZoom)
      const transform = this.zoomFit(g)
      d3.select('#bridge').call(zoom).call(zoom.transform, transform)
      d3.select('#bridge').on('dblclick.zoom', null)

      if (this.selectedPart !== false && this.selectedPart) {
        // let splitPart = this.selectedPart.split('-')
        // splitPart = splitPart.slice(1)
        this.changeColor(this.selectedPart)
      }
    },

    // const classList = document.querySelectorAll('.textLayer')
    // const classList = document.getElementsByClassName('.textLayer')

    // for (let j = 0; j < classList.length; j++) {
    //   classList[j].classList.remove('hide')
    //   console.log(classList[j])
    // }
    // document.getElementById("mytext").classList.remove('hide')

    bridgeComponentClickObject (
      sectorID,
      sectorNo,
      componentID,
      componentNo,
      text,
      back,
      action
    ) {
      const result = {
        sectorID: sectorID,
        sectorNo: sectorNo,
        componentID: componentID,
        componentNo: componentNo,
        text: text,
        back: back,
        action: action
      }
      this.$emit('clicked', result)
      console.log(result)
    },
    changeColor (id) {
      if (this.lastItemId !== undefined && this.lastItemColor !== undefined) {
        d3.select('#' + this.lastItemId).attr('style', this.lastItemColor)
      }
      this.lastItemId = id
      if (this.beforeHoverColor) {
        this.lastItemColor = this.beforeHoverColor
      } else this.lastItemColor = d3.select('#' + id).attr('style')
      d3.select('#' + id).attr(
        'style',
        'fill:#BEDF76;stroke-width:1;stroke:rgb(0,0,0)'
      )
      // d3.select('#' + id).attr('style', 'fill: white;stroke-width:3;stroke:#BEDF76')
    }
  },
  watch: {
    value () {
      this.valueInside = this.value
    },
    valueInside () {
      this.$emit('input', this.valueInside)
    }
  }
}
</script>
<style>
.hide {
  display: none;
}
.hoverItem {
  cursor: pointer;
}
</style>
<style scoped lang="scss">
img,
span {
  position: absolute;
}
.profile-view {
  position: relative;
  height: 100%;
}
.spn-center {
  top: 180px;
}
.spn-joint {
  top: 4px;
}
.center.first.wing {
  left: 9px;
}
</style>
