<template>
  <div style="height: 100%">
    <v-btn
      class="pa-3"
      style="position: absolute; z-index: 5"
      @click="goToOverview({ back: true })"
    >
      Back
    </v-btn>
    <div
      v-if="resultNull == true"
      style="padding-top: 50px; padding-left: 20px"
    >
      ไม่มีข้อมูลของชิ้นส่วนสะพานนี้
    </div>
    <BridgeSideView
      v-if="generateBridge == 'sideView'"
      v-model="bridgeSideViewValue"
      :bridgeType="bridgeType"
      :bridgePartIndex="seq"
      :bridgePartCount="bridgePartCount"
      :direction="bridgeDirection"
      :startLocation="startLocation"
      :endLocation="endLocation"
      :brFlag="brFlag"
      :bridgeComponentPartSelected="bridgeComponentPartSelected"
      @clicked="goToOverview"
    />
    <BridgeTopView
      v-if="generateBridge == 'topView'"
      v-model="bridgeTopViewValue"
      :bridgeType="bridgeType"
      :bridgePartIndex="seq"
      :bridgePartCount="bridgePartCount"
      :direction="bridgeDirection"
      :startLocation="startLocation"
      :endLocation="endLocation"
      :brFlag="brFlag"
      :disableDirection="topViewDisatbleDirection"
      :bridgeComponentPartSelected="bridgeComponentPartSelected"
      :bridgePart="bridgePart"
      :currentBridgePart="currentBridgePart"
      :isIndept="isIndept"
      :indeptSectorComplete="indeptSectorComplete"
      :selectedPart="selectedPart"
      @clicked="goToOverview"
    />
    <BridgeBottomView
      v-if="generateBridge == 'bottomView'"
      v-model="bridgeBottomView"
      :bridgeType="bridgeType"
      :bridgePartIndex="seq"
      :bridgePartCount="bridgePartCount"
      :bridgePartCountMinor="bridgePartCountMinor"
      :direction="bridgeDirection"
      :startLocation="startLocation"
      :endLocation="endLocation"
      :brFlag="brFlag"
      :bridgeComponentPartSelected="bridgeComponentPartSelected"
      :isIndept="isIndept"
      :indeptSectorComplete="indeptSectorComplete"
      :selectedPart="selectedPart"
      :bridgePartId="bridgePartId"
      @clicked="goToOverview"
    />
    <BridgeCrossSectionView
      v-if="generateBridge == 'crossSectionView'"
      v-model="bridgeCrossSectionView"
      :bridgeType="bridgeType"
      :bridgePartIndex="seq"
      :bridgePartCount="bridgePartCount"
      :bridgeFootCount="bridgeFootCount"
      :bridgeBsCount="bridgeBsCount"
      :direction="bridgeDirection"
      :startLocation="startLocation"
      :endLocation="endLocation"
      :brFlag="brFlag"
      :bridgeComponentPartSelected="bridgeComponentPartSelected"
      :disableDirection="crossSectionViewDisatbleDirection"
      :isIndept="isIndept"
      :indeptSectorComplete="indeptSectorComplete"
      :selectedPart="selectedPart"
      @clicked="goToOverview"
    />
  </div>
</template>

<script>
import BridgeSideView from '@/components/bridge/bridgeSideView.vue'
import BridgeTopView from '@/components/bridge/bridgeTopView.vue'
import BridgeBottomView from '@/components/bridge/bridgeBottomView.vue'
import BridgeCrossSectionView from '@/components/bridge/bridgeCrossSectionView.vue'

export default {
  components: {
    BridgeSideView,
    BridgeTopView,
    BridgeBottomView,
    BridgeCrossSectionView
  },
  props: {
    value: Boolean,
    bridgeView: String,
    bridgeDirection: String,
    startLocation: String,
    endLocation: String,
    brFlag: Number,
    bridgeComponentPartSelected: Object,
    bridgeSegmentType: String,
    // bridgeFootCount: Number,
    // bridgeBsCount: Number,
    bridgeSelected: Object,
    seq: Number,
    isIndept: Boolean,
    indeptSectorComplete: Object,
    selectedPart: String,
    bridgePartId: Number
  },
  data: () => ({
    resultNull: false,
    bridgeSideViewValue: false,
    bridgeTopViewValue: false,
    bridgeBottomView: false,
    bridgeCrossSectionView: false,
    bridgePartCount: 1,
    bridgePartCountMinor: 1,
    bridgeFootCount: 1,
    bridgeBsCount: 1,
    bridgeType: 'BG',
    generateBridge: '',
    height: 1000,
    width: 1000,
    topViewDisatbleDirection: false,
    crossSectionViewDisatbleDirection: false,
    bridgePart: [],
    currentBridgePart: ''
  }),
  mounted () {
    this.bridgePart = this.bridgeDetail.bridgeOverview.bridge_part
    this.currentBridgePart = this.bridgeSelected.partcode
    if (this.bridgeView === 'bottomView') {
      this.getBridgeSegment(
        this.bridgeDetail.detail.id,
        this.bridgeSelected.partcode,
        this.seq
      )
    } else if (this.bridgeView === 'crossSectionView') {
      this.getBridgePier(
        this.bridgeDetail.detail.id,
        this.bridgeSelected.partcode,
        this.seq
      )
    } else {
      this.getBridgeSegment(
        this.bridgeDetail.detail.id,
        this.bridgeSelected.partcode,
        this.seq
      )
      // this.generateBridge = this.bridgeView
    }
  },
  computed: {
    bridgeDetail () {
      return this.$store.state.bridgeDetail
    }
  },
  methods: {
    goToOverview (value) {
      this.$emit('clicked', value)
    },
    async getBridgeSegment (bridgeCode, partCode, seq) {
      const bridgeSegmentResult = await this.$store.dispatch(
        'getBridgeSegment',
        {
          bridgeCode,
          partCode,
          seq
        }
      )
      if (bridgeSegmentResult == null) {
        this.resultNull = true
      } else {
        this.resultNull = false
        this.bridgeType = bridgeSegmentResult.bridge_segment.type_id
        if (this.bridgeType === 'ST') {
          this.bridgePartCount = 1
        } else if (this.bridgeType === 'PG') {
          this.bridgePartCount = parseInt(
            bridgeSegmentResult.bridge_segment.amount_beam_major
          )
          // if (this.bridgePartCount === null) {
          //   this.bridgePartCount = parseInt(bridgeSegmentResult.bridge_segment.amount_beam_major)
          // }
        } else if (this.bridgeType === 'IG') {
          this.bridgePartCount = parseInt(
            bridgeSegmentResult.bridge_segment.amount_beam_major
          )
          this.bridgePartCountMinor = parseInt(
            bridgeSegmentResult.bridge_segment.amount_beam_minor
          )
        } else {
          this.bridgePartCount = parseInt(
            bridgeSegmentResult.bridge_segment.amount_beam_major
          )
        }
        this.generateBridge = this.bridgeView
      }
    },
    async getBridgePier (bridgeCode, partCode, seq) {
      const bridgePierResult = await this.$store.dispatch('getBridgePier', {
        bridgeCode: bridgeCode,
        partCode: partCode,
        seq: seq
      })
      if (bridgePierResult == null) {
        this.resultNull = true
      } else {
        this.resultNull = false
        console.log('get bridge pier')
        console.log(this.bridgeComponentPartSelected)
        if (this.bridgeComponentPartSelected.componentID === 'A') {
          this.bridgeType = this.bridgeSegmentType + '-A'
        } else {
          if (bridgePierResult.bridge_pier.type_id !== 'PC') {
            this.bridgeType =
              this.bridgeSegmentType +
              '-' +
              bridgePierResult.bridge_pier.type_id
          } else {
            this.bridgeType = bridgePierResult.bridge_pier.type_id
          }
        }
        this.bridgeFootCount = parseInt(bridgePierResult.bridge_pier.amont_pr)
        this.bridgeBsCount = parseInt(bridgePierResult.bridge_pier.amount_bc)
        this.generateBridge = this.bridgeView
      }
      // console.log(this.bridgeType)
      // this.bridgeType = 'BG-PW'
      // this.bridgeBsCount = 3
      // this.bridgeFootCount = 5
    }
  }
}
</script>

<style scoped lang="scss"></style>
