<template>
  <div style="height: 100%; position: relative">
    <div class="profile-view">
      <svg width="100%" height="100%" id="bridge">
        <g></g>
      </svg>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import { mapState } from 'vuex'
export default {
  props: {
    value: Boolean,
    bridgeType: String,
    bridgePartIndex: Number,
    bridgePartCount: Number,
    direction: String,
    startLocation: String,
    endLocation: String,
    brFlag: Number,
    bridgeComponentPartSelected: Object,
    disableDirection: Boolean,
    bridgePart: Array,
    currentBridgePart: String,
    isIndept: Boolean,
    indeptSectorComplete: Object,
    selectedPart: String
  },
  data: () => ({
    imgPath: {
      valueInside: false,
      topDNL: require('@/assets/img/bridge_component/top/drainage_l.png'),
      topDNR: require('@/assets/img/bridge_component/top/drainage_r.png'),
      topSurface: require('@/assets/img/bridge_component_svg/top/surface_1.svg'),
      topSurfaceHover: require('@/assets/img/bridge_component_svg/top/surface_hover.svg'),
      topSurfaceFinish: require('@/assets/img/bridge_component_svg/top/surface_hover (selected).svg'),
      topRLL: require('@/assets/img/bridge_component_svg/top/drainage_l.svg'),
      topRLLHover: require('@/assets/img/bridge_component_svg/top/guardrail_l_hover.svg'),
      topRLLFinish: require('@/assets/img/bridge_component_svg/top/guardrail_l_hover (selected).svg'),
      topRLR: require('@/assets/img/bridge_component_svg/top/drainage_r.svg'),
      topRLRHover: require('@/assets/img/bridge_component_svg/top/guardrail_R_hover.svg'),
      topRLRFinish: require('@/assets/img/bridge_component_svg/top/guardrail_R_hover (selected).svg')
    },
    data: [],
    width: 600,
    height: 400,
    numPoints: 100,
    zoom: 0,
    lastItemId: undefined,
    lastItemColor: undefined,
    bridgeJointType: 'single' // single, start, between, end
  }),
  computed: {
    ...mapState('inspectionDamageDialog', {
      inspectionType: 'inspectionType'
    })
  },
  mounted () {
    this.valueInside = this.value
    if (this.bridgePart.length > 1) {
      const currentBridgePart = this.bridgePart.find(
        (bridge) => bridge[0].partcode === this.currentBridgePart
      )
      const currentBridgeSegment = currentBridgePart.bridge_segment.find(
        (segment) => parseInt(segment.seq) === this.bridgePartIndex
      )
      const componentRight = currentBridgeSegment.component_right
      const componentLeft = currentBridgeSegment.component_left
      if (componentRight === 'RL' && componentLeft === 'RL') {
        this.bridgeJointType = 'single'
      } else if (componentLeft === 'RL') {
        this.bridgeJointType = 'start'
      } else if (componentRight === 'RL') {
        this.bridgeJointType = 'end'
      } else {
        this.bridgeJointType = 'between'
      }
      // if (this.bridgePart[0][0].partcode === this.currentBridgePart) {
      //   if (this.currentBridgePart[0] === 'R') {
      //     this.bridgeJointType = 'start'
      //   } else {
      //     this.bridgeJointType = 'end'
      //   }
      // } else if (
      //   this.bridgePart[this.bridgePart.length - 1][0].partcode ===
      //   this.currentBridgePart
      // ) {
      //   if (this.currentBridgePart[0] === 'R') {
      //     this.bridgeJointType = 'end'
      //   } else {
      //     this.bridgeJointType = 'start'
      //   }
      // } else {
      //   this.bridgeJointType = 'between'
      // }
    }
    console.log(this.bridgeJointType)
    this.initZoom()
    setTimeout(() => {
      // this.$refs.ooo.style.width = '2500'
      var all = document.getElementsByClassName('profile-view')
      for (var i = 0; i < all.length; i++) {
        all[i].style.width = '100%'
      }
    }, 200)
  },
  methods: {
    handleZoom (e) {
      d3.select('svg g').attr('transform', e.transform)
    },
    zoomFit (g) {
      const bounds = g.node().getBBox()
      const parent = g.node().parentElement
      const fullWidth = parent.clientWidth || parent.parentNode.clientWidth
      const fullHeight = parent.clientHeight || parent.parentNode.clientHeight
      const width = bounds.width
      const height = bounds.height + 50
      const midX = bounds.x + width / 2
      const midY = bounds.y + height / 2
      let scale = 0.85 / Math.max(width / fullWidth, height / fullHeight)
      let translate = [
        fullWidth / 2 - scale * midX,
        fullHeight / 2 - scale * midY
      ]
      if (width === 0 || height === 0) {
        scale = 1
        translate = [0, 0]
      }
      const transform = d3.zoomIdentity
        .translate(translate[0], translate[1])
        .scale(scale)
      return transform
    },
    changeColor (id) {
      if (this.lastItemId !== undefined && this.lastItemColor !== undefined) {
        if (
          this.lastItemId === 'AP1' ||
          this.lastItemId === 'WS1' ||
          this.lastItemId === 'RL1' ||
          this.lastItemId === 'RL2'
        ) {
          d3.select('#' + this.lastItemId).attr(
            'xlink:href',
            this.lastItemColor
          )
        } else {
          d3.select('#' + this.lastItemId).attr('style', this.lastItemColor)
        }
      }
      this.lastItemId = id
      if (id === 'AP1' || id === 'WS1' || id === 'RL1' || id === 'RL2') {
        this.lastItemColor = d3.select('#' + id).attr('xlink:href')
      } else {
        this.lastItemColor = d3.select('#' + id).attr('style')
      }
      if (id === 'AP1' || id === 'WS1') {
        d3.select('#' + id).attr('xlink:href', this.imgPath.topSurfaceHover)
      } else if (id === 'RL1') {
        d3.select('#' + id).attr('xlink:href', this.imgPath.topRLLHover)
      } else if (id === 'RL2') {
        d3.select('#' + id).attr('xlink:href', this.imgPath.topRLRHover)
      } else if (id === 'DN1') {
        d3.select('#' + id).attr(
          'style',
          'fill:rgb(190, 223, 118);stroke-width:1;stroke:rgb(0,0,0)'
        )
      } else if (id === 'DN2') {
        d3.select('#' + id).attr(
          'style',
          'fill:rgb(190, 223, 118);stroke-width:1;stroke:rgb(0,0,0)'
        )
      } else if (id === 'LJ1') {
        d3.select('#' + id).attr(
          'style',
          'fill:rgb(190, 223, 118);stroke-width:1;stroke:rgb(0,0,0)'
        )
      }
    },
    async initZoom () {
      const self = this

      const g = d3.select('g')
      let styleColor = this.imgPath.topSurface

      if (this.bridgeComponentPartSelected.componentID === 'A') {
        if (this.isIndept) {
          styleColor = this.imgPath.topSurface
          for (let x = 0; x < this.indeptSectorComplete.AP.items.length; x++) {
            if (this.indeptSectorComplete.AP.items[x] === '1') {
              styleColor = this.imgPath.topSurfaceFinish
            }
          }
        } else {
          styleColor = this.imgPath.topSurface
        }
        g.append('svg:image')
          .attr('id', function () {
            return 'AP1'
          })
          .attr('height', 208)
          .attr('x', 272)
          .attr('y', 148)
          .attr('xlink:href', styleColor)
          .attr('class', 'hoverItem')
          .on('click', function () {
            self.bridgeComponentClickObject(
              'A',
              self.bridgePartIndex,
              'AP',
              1,
              'A' + self.bridgePartIndex.toString() + '-AP1',
              false,
              true
            )
            self.changeColor('AP1')
          })
        g.append('text')
          .text('A' + this.bridgePartIndex.toString() + '-AP1')
          .attr('x', 540)
          .attr('y', 130)
          .attr('class', 'textLayer')
          .attr('class', 'hoverItem')
          .on('click', function () {
            self.bridgeComponentClickObject(
              'A',
              self.bridgePartIndex,
              'AP',
              1,
              'A' + self.bridgePartIndex.toString() + '-AP1',
              false,
              true
            )
            self.changeColor('AP1')
          })
        if (this.isIndept) {
          styleColor = this.imgPath.topRLL
          for (let x = 0; x < this.indeptSectorComplete.RL.items.length; x++) {
            if (this.indeptSectorComplete.RL.items[x] === '1') {
              styleColor = this.imgPath.topRLLFinish
            }
          }
        } else {
          styleColor = this.imgPath.topRLL
        }
        g.append('svg:image')
          .attr('id', function () {
            return 'RL1'
          })
          .attr('height', 230)
          .attr('x', 250)
          .attr('y', 108)
          .attr('xlink:href', styleColor)
          .attr('class', 'hoverItem')
          .on('click', function () {
            self.bridgeComponentClickObject(
              'A',
              self.bridgePartIndex,
              'RL',
              1,
              'A' + self.bridgePartIndex.toString() + '-RL1',
              false,
              true
            )
            self.changeColor('RL1')
          })
        g.append('text')
          .text('A' + this.bridgePartIndex.toString() + '-RL1')
          .attr('x', 275)
          .attr('y', 130)
          .attr('class', 'textLayer')
          .attr('class', 'hoverItem')
          .on('click', function () {
            self.bridgeComponentClickObject(
              'A',
              self.bridgePartIndex,
              'RL',
              1,
              'A' + self.bridgePartIndex.toString() + '-RL1',
              false,
              true
            )
            self.changeColor('RL1')
          })
        if (this.isIndept) {
          styleColor = this.imgPath.topRLR
          for (let x = 0; x < this.indeptSectorComplete.RL.items.length; x++) {
            if (this.indeptSectorComplete.RL.items[x] === '2') {
              styleColor = this.imgPath.topRLRFinish
            }
          }
        } else {
          styleColor = this.imgPath.topRLR
        }
        g.append('svg:image')
          .attr('id', function () {
            return 'RL2'
          })
          .attr('height', 230)
          .attr('x', 751)
          .attr('y', 105)
          .attr('xlink:href', styleColor)
          .attr('class', 'hoverItem')
          .on('click', function () {
            self.bridgeComponentClickObject(
              'A',
              self.bridgePartIndex,
              'RL',
              2,
              'A' + self.bridgePartIndex.toString() + '-RL2',
              false,
              true
            )
            self.changeColor('RL2')
          })
        g.append('text')
          .text('A' + this.bridgePartIndex.toString() + '-RL2')
          .attr('x', 810)
          .attr('y', 130)
          .attr('class', 'textLayer')
          .attr('class', 'hoverItem')
          .on('click', function () {
            self.bridgeComponentClickObject(
              'A',
              self.bridgePartIndex,
              'RL',
              2,
              'A' + self.bridgePartIndex.toString() + '-RL2',
              false,
              true
            )
            self.changeColor('RL2')
          })
      }
      if (this.bridgeComponentPartSelected.componentID === 'S') {
        if (this.isIndept) {
          styleColor = this.imgPath.topSurface
          console.log(this.indeptSectorComplete)
          for (let x = 0; x < this.indeptSectorComplete.WS.items.length; x++) {
            if (this.indeptSectorComplete.WS.items[x] === '1') {
              styleColor = this.imgPath.topSurfaceFinish
            }
          }
        } else {
          styleColor = this.imgPath.topSurface
        }
        g.append('svg:image')
          .attr('id', function () {
            return 'WS1'
          })
          .attr('height', 208)
          .attr('x', 272)
          .attr('y', 148)
          .attr('xlink:href', styleColor)
          .attr('class', 'hoverItem')
          // .on('click', function () { self.bridgeComponentClick('S' + this.bridgePartIndex.toString() + '-WS1') })
          .on('click', function () {
            self.bridgeComponentClickObject(
              'S',
              self.bridgePartIndex,
              'WS',
              1,
              'S' + self.bridgePartIndex.toString() + '-WS1',
              false,
              true
            )
            self.changeColor('WS1')
          })
        g.append('text')
          .text('S' + this.bridgePartIndex.toString() + '-WS1')
          .attr('x', 540)
          .attr('y', 130)
          .attr('class', 'textLayer')
          .attr('class', 'hoverItem')
          // .on('click', function () { self.bridgeComponentClick('S' + this.bridgePartIndex.toString() + '-WS1') })
          .on('click', function () {
            self.bridgeComponentClickObject(
              'S',
              self.bridgePartIndex,
              'WS',
              1,
              'S' + self.bridgePartIndex.toString() + '-WS1',
              false,
              true
            )
            self.changeColor('WS1')
          })
        if (
          this.bridgeJointType === 'start' ||
          this.bridgeJointType === 'single'
        ) {
          if (this.isIndept) {
            styleColor = this.imgPath.topRLL
            for (
              let x = 0;
              x < this.indeptSectorComplete.RL.items.length;
              x++
            ) {
              if (this.indeptSectorComplete.RL.items[x] === '1') {
                styleColor = this.imgPath.topRLLFinish
              }
            }
          } else {
            styleColor = this.imgPath.topRLL
          }
          g.append('svg:image')
            .attr('id', function () {
              return 'RL1'
            })
            .attr('height', 230)
            .attr('x', 250)
            .attr('y', 108)
            .attr('xlink:href', styleColor)
            .attr('class', 'hoverItem')
            // .on('click', function () { self.bridgeComponentClick('S' + this.bridgePartIndex.toString() + '-RL1') })
            .on('click', function () {
              self.bridgeComponentClickObject(
                'S',
                self.bridgePartIndex,
                'RL',
                1,
                'S' + self.bridgePartIndex.toString() + '-RL1',
                false,
                true
              )
              self.changeColor('RL1')
            })
          g.append('text')
            .text('S' + this.bridgePartIndex.toString() + '-RL1')
            .attr('x', 275)
            .attr('y', 130)
            .attr('class', 'textLayer')
            .attr('class', 'hoverItem')
            // .on('click', function () { self.bridgeComponentClick('S' + this.bridgePartIndex.toString() + '-RL1') })
            .on('click', function () {
              self.bridgeComponentClickObject(
                'S',
                self.bridgePartIndex,
                'RL',
                1,
                'S' + self.bridgePartIndex.toString() + '-RL1',
                false,
                true
              )
              self.changeColor('RL1')
            })
          if (this.isIndept) {
            styleColor =
              'fill:rgb(252, 190, 1);stroke-width:1;stroke:rgb(53,86,142)'
            for (
              let x = 0;
              x < this.indeptSectorComplete.DN.items.length;
              x++
            ) {
              if (this.indeptSectorComplete.DN.items[x] === '1') {
                styleColor =
                  'fill:rgb(255, 69, 0);stroke-width:1;stroke:rgb(53,86,142)'
              }
            }
          } else {
            styleColor =
              'fill:rgb(252, 190, 1);stroke-width:1;stroke:rgb(53,86,142)'
          }
          g.append('polygon')
            .attr('id', function () {
              return 'DN1'
            })
            .attr('points', '395,151 410,151 335,333 320,333')
            .attr('style', styleColor)
            .attr('class', 'hoverItem')
            .on('click', function () {
              self.bridgeComponentClickObject(
                'S',
                self.bridgePartIndex,
                'DN',
                1,
                'S' + self.bridgePartIndex.toString() + '-DN1',
                false,
                true
              )
              self.changeColor('DN1')
            })
          g.append('text')
            .text('S' + this.bridgePartIndex.toString() + '-DN1')
            .attr('x', 310)
            .attr('y', 370)
            .attr('class', 'textLayer')
            .attr('class', 'hoverItem')
            .on('click', function () {
              self.bridgeComponentClickObject(
                'S',
                self.bridgePartIndex,
                'DN',
                1,
                'S' + self.bridgePartIndex.toString() + '-DN1',
                false,
                true
              )
              self.changeColor('DN1')
            })
        } else {
          if (this.isIndept) {
            styleColor =
              'fill:rgb(252, 190, 1);stroke-width:1;stroke:rgb(53,86,142)'
            for (
              let x = 0;
              x < this.indeptSectorComplete.RL.items.length;
              x++
            ) {
              if (this.indeptSectorComplete.RL.items[x] === '1') {
                styleColor =
                  'fill:rgb(255, 69, 0);stroke-width:1;stroke:rgb(53,86,142)'
              }
            }
          } else {
            styleColor =
              'fill:rgb(252, 190, 1);stroke-width:1;stroke:rgb(53,86,142)'
          }
          g.append('polygon')
            .attr('points', '370,151 400,151 318,329 281,329')
            .attr('style', styleColor)
            .attr('opacity', 0.3)
        }
        if (
          this.bridgeJointType === 'end' ||
          this.bridgeJointType === 'single'
        ) {
          if (this.isIndept) {
            styleColor = this.imgPath.topRLR
            for (
              let x = 0;
              x < this.indeptSectorComplete.RL.items.length;
              x++
            ) {
              if (this.indeptSectorComplete.RL.items[x] === '2') {
                styleColor = this.imgPath.topRLRFinish
              }
            }
          } else {
            styleColor = this.imgPath.topRLR
          }
          g.append('svg:image')
            .attr('id', function () {
              return 'RL2'
            })
            .attr('height', 230)
            .attr('x', 751)
            .attr('y', 105)
            .attr('xlink:href', styleColor)
            .attr('class', 'hoverItem')
            .on('click', function () {
              self.bridgeComponentClickObject(
                'S',
                self.bridgePartIndex,
                'RL',
                2,
                'S' + self.bridgePartIndex.toString() + '-RL2',
                false,
                true
              )
              self.changeColor('RL2')
            })
          g.append('text')
            .text('S' + this.bridgePartIndex.toString() + '-RL2')
            .attr('x', 810)
            .attr('y', 130)
            .attr('class', 'textLayer')
            .attr('class', 'hoverItem')
            .on('click', function () {
              self.bridgeComponentClickObject(
                'S',
                self.bridgePartIndex,
                'RL',
                2,
                'S' + self.bridgePartIndex.toString() + '-RL2',
                false,
                true
              )
              self.changeColor('RL2')
            })
          if (this.isIndept) {
            styleColor =
              'fill:rgb(252, 190, 1);stroke-width:1;stroke:rgb(53,86,142)'
            for (
              let x = 0;
              x < this.indeptSectorComplete.DN.items.length;
              x++
            ) {
              if (this.indeptSectorComplete.DN.items[x] === '2') {
                styleColor =
                  'fill:rgb(255, 69, 0);stroke-width:1;stroke:rgb(53,86,142)'
              }
            }
          } else {
            styleColor =
              'fill:rgb(252, 190, 1);stroke-width:1;stroke:rgb(53,86,142)'
          }
          g.append('polygon')
            .attr('id', function () {
              return 'DN2'
            })
            .attr('points', '743,151 758,151 834,333 817,333')
            .attr('style', styleColor)
            .attr('class', 'hoverItem')
            .on('click', function () {
              self.bridgeComponentClickObject(
                'S',
                self.bridgePartIndex,
                'DN',
                2,
                'S' + self.bridgePartIndex.toString() + '-DN2',
                false,
                true
              )
              self.changeColor('DN2')
            })
          g.append('text')
            .text('S' + this.bridgePartIndex.toString() + '-DN2')
            .attr('x', 780)
            .attr('y', 370)
            .attr('class', 'textLayer')
            .attr('class', 'hoverItem')
            .on('click', function () {
              self.bridgeComponentClickObject(
                'S',
                self.bridgePartIndex,
                'DN',
                2,
                'S' + self.bridgePartIndex.toString() + '-DN2',
                false,
                true
              )
              self.changeColor('DN2')
            })
        } else {
          if (this.isIndept) {
            styleColor =
              'fill:rgb(252, 190, 1);stroke-width:1;stroke:rgb(53,86,142)'
            for (
              let x = 0;
              x < this.indeptSectorComplete.DN.items.length;
              x++
            ) {
              if (this.indeptSectorComplete.DN.items[x] === '2') {
                styleColor =
                  'fill:rgb(255, 69, 0);stroke-width:1;stroke:rgb(53,86,142)'
              }
            }
          } else {
            styleColor =
              'fill:rgb(252, 190, 1);stroke-width:1;stroke:rgb(53,86,142)'
          }
          const checkallow = await this.$store.dispatch(
            'bridgeDetail/checkIsAllowed',
            {
              componentId: 'LJ',
              inspectionType: this.inspectionType
            }
          )
          g.append('polygon')
            .attr('id', function () {
              return 'LJ1'
            })
            .attr('points', '758,151 788,151 875,330 837,330')
            .attr('style', styleColor)
            .attr('class', checkallow ? 'hoverItem' : null)
            .on(
              'click',
              checkallow
                ? function () {
                  self.bridgeComponentClickObject(
                    'S',
                    self.bridgePartIndex,
                    'LJ',
                    1,
                    'S' + self.bridgePartIndex.toString() + '-LJ1',
                    false,
                    true
                  )
                  self.changeColor('LJ1')
                }
                : null
            )
          g.append('text')
            .text('S' + this.bridgePartIndex.toString() + '-LJ1')
            .attr('x', 780)
            .attr('y', 370)
            .attr('class', 'textLayer')
            .attr('class', checkallow ? 'hoverItem' : null)
            .on(
              'click',
              checkallow
                ? function () {
                  self.bridgeComponentClickObject(
                    'S',
                    self.bridgePartIndex,
                    'LJ',
                    1,
                    'S' + self.bridgePartIndex.toString() + '-LJ1',
                    false,
                    true
                  )
                  self.changeColor('LJ1')
                }
                : null
            )
        }
      }
      if (this.disableDirection === true) {
        if (this.direction === 'right') {
          g.append('line')
            .style('stroke', 'black')
            .style('stroke-width', 3)
            .attr('x1', 400)
            .attr('y1', 50)
            .attr('x2', 800)
            .attr('y2', 50)
            .attr('marker-end', 'url(#triangle_fwd)')
        } else if (this.direction === 'left') {
          g.append('line')
            .style('stroke', 'black')
            .style('stroke-width', 3)
            .attr('x1', 400)
            .attr('y1', 50)
            .attr('x2', 800)
            .attr('y2', 50)
            .attr('marker-start', 'url(#triangle_bwd)')
        }

        g.append('text')
          .text('ทิศทางจราจร')
          .attr('x', 550)
          .attr('y', 30)
          .attr('class', 'textLayer')

        g.append('text')
          .text('กม. น้อย')
          .attr('x', 100)
          .attr('y', 275)
          .attr('class', 'textLayer')

        g.append('text')
          .text('กม. มาก')
          .attr('x', 900)
          .attr('y', 274)
          .attr('class', 'textLayer')

        g.append('text')
          .text('ไป ' + this.startLocation)
          .attr('x', 100)
          .attr('y', 210)
          .attr('class', 'textLayer')

        g.append('text')
          .text('ไป ' + this.endLocation)
          .attr('x', 900)
          .attr('y', 210)
          .attr('class', 'textLayer')

        g.append('line')
          .style('stroke', 'black')
          .style('stroke-width', 3)
          .attr('x1', 100)
          .attr('y1', 180)
          .attr('x2', 250)
          .attr('y2', 180)
          .attr('marker-start', 'url(#triangle_bwd)')

        g.append('line')
          .style('stroke', 'black')
          .style('stroke-width', 3)
          .attr('x1', 900)
          .attr('y1', 180)
          .attr('x2', 1050)
          .attr('y2', 180)
          .attr('marker-end', 'url(#triangle_fwd)')
      }

      g.append('svg:defs')
        .append('svg:marker')
        .attr('id', 'triangle_fwd')
        .attr('refX', 2)
        .attr('refY', 2)
        .attr('markerWidth', 30)
        .attr('markerHeight', 30)
        .attr('orient', '0deg')
        .append('path')
        .attr('d', 'M 0 0 4 2 0 4 1 2')
        .style('fill', 'black')

      g.append('svg:defs')
        .append('svg:marker')
        .attr('id', 'triangle_bwd')
        .attr('refX', 2)
        .attr('refY', 2)
        .attr('markerWidth', 30)
        .attr('markerHeight', 30)
        .attr('orient', '180deg')
        .append('path')
        .attr('d', 'M 0 0 4 2 0 4 1 2')
        .style('fill', 'black')

      const zoom = d3.zoom().scaleExtent([0.05, 5]).on('zoom', this.handleZoom)
      const transform = this.zoomFit(g)
      d3.select('#bridge').call(zoom).call(zoom.transform, transform)
      d3.select('#bridge').on('dblclick.zoom', null)

      if (this.selectedPart !== false && this.selectedPart) {
        console.log(this.selectedPart)
        let splitPart = this.selectedPart.split('-')
        splitPart = splitPart.slice(1)
        this.changeColor(splitPart.join('-'))
      }
    },
    bridgeComponentClickObject (
      sectorID,
      sectorNo,
      componentID,
      componentNo,
      text,
      back,
      action
    ) {
      const result = {
        sectorID: sectorID,
        sectorNo: sectorNo,
        componentID: componentID,
        componentNo: componentNo,
        text: text,
        back: back,
        action: action
      }
      this.$emit('clicked', result)
    }
  },
  watch: {
    value () {
      this.valueInside = this.value
    },
    valueInside () {
      this.$emit('input', this.valueInside)
    }
  }
}
</script>
<style>
.hide {
  display: none;
}
</style>
<style scoped lang="scss">
img,
span {
  position: absolute;
}
.profile-view {
  position: relative;
  height: 100%;
}
.spn-center {
  top: 180px;
}
.spn-joint {
  top: 4px;
}
.center.first.wing {
  left: 9px;
}
</style>
