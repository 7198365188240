<template>
  <div style="height: 100%; position: relative">
    <div class="profile-view">
      <svg width="100%" height="100%" id="bridge">
        <g></g>
      </svg>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import { mapState } from 'vuex'
export default {
  props: {
    value: Boolean,
    bridgeType: String,
    bridgePartIndex: Number,
    bridgeFootCount: Number,
    bridgeBsCount: Number,
    direction: String,
    startLocation: String,
    endLocation: String,
    brFlag: Number,
    disableDirection: Boolean,
    isIndept: Boolean,
    indeptSectorComplete: Object,
    selectedPart: String
  },
  computed: {
    ...mapState('inspectionDamageDialog', {
      inspectionType: 'inspectionType'
    })
  },
  data: () => ({
    imgPath: {
      valueInside: false,
      crossBG: require('@/assets/img/bridge_component_svg/cross_section/gd_box.svg'),
      crossIG: require('@/assets/img/bridge_component_svg/cross_section/gd_i.svg'),
      crossMB: require('@/assets/img/bridge_component_svg/cross_section/multi_beam.svg'),
      crossPG: require('@/assets/img/bridge_component_svg/cross_section/gd_plank.svg'),
      crossSG: require('@/assets/img/bridge_component_svg/cross_section/segmental_gd_box_pr.svg'),
      crossSGClick: require('@/assets/img/bridge_component_svg/cross_section/segmental_gd_box_pr_hover.svg'),
      crossSGFinish: require('@/assets/img/bridge_component_svg/cross_section/segmental_gd_box_pr_hover (selected).svg'),
      crossUG: require('@/assets/img/bridge_component_svg/cross_section/U-Girder_.svg'),
      crossRLeft: require('@/assets/img/bridge_component_svg/cross_section/r_left.svg'),
      crossRRight: require('@/assets/img/bridge_component_svg/cross_section/r_right.svg')
    },
    data: [],
    zoom: 0,
    height: 1000,
    width: 1000,
    bridgeWidth: 500,
    lastItemId: undefined,
    lastItemColor: undefined
  }),
  mounted () {
    this.valueInside = this.value
    console.log('init cross section')
    this.width = document.querySelector('.profile-view').clientWidth
    this.height = document.querySelector('.profile-view').clientHeight
    if (
      this.bridgeType === 'BG-A' ||
      this.bridgeType === 'BG-PW' ||
      this.bridgeType === 'BG-PB'
    ) {
      this.bridgePartCount = 20
    } else {
      this.bridgePartCount = 20
    }
    this.initZoom()
    setTimeout(() => {
      console.log(this.$refs.ooo)

      // this.$refs.ooo.style.width = '2500'
      var all = document.getElementsByClassName('profile-view')
      console.log(all)
      for (var i = 0; i < all.length; i++) {
        all[i].style.width = '100%'
      }
    }, 200)
  },
  methods: {
    handleZoom (e) {
      d3.select('svg g').attr('transform', e.transform)
    },
    zoomFit (g) {
      const bounds = g.node().getBBox()
      const parent = g.node().parentElement
      const fullWidth = parent.clientWidth || parent.parentNode.clientWidth
      const fullHeight = parent.clientHeight || parent.parentNode.clientHeight
      const width = bounds.width + 100
      const height = bounds.height + 190
      const midX = bounds.x + width / 2
      const midY = bounds.y + height / 2
      let scale = 0.85 / Math.max(width / fullWidth, height / fullHeight)
      let translate = [
        fullWidth / 2 - scale * midX,
        fullHeight / 2 - scale * midY
      ]
      if (width === 0 || height === 0) {
        scale = 1
        translate = [0, 0]
      }
      console.log(width)
      console.log(height)
      const transform = d3.zoomIdentity
        .translate(translate[0], translate[1])
        .scale(scale)
      return transform
    },
    changeColor (id) {
      if (
        this.bridgeType.charAt(0) === 'S' &&
        this.bridgeType.charAt(1) === 'G'
      ) {
        if (this.lastItemId !== undefined && this.lastItemColor !== undefined) {
          if (
            this.lastItemId.charAt(0) === 'P' &&
            this.lastItemId.charAt(1) === 'R'
          ) {
            d3.select('#' + this.lastItemId).attr(
              'xlink:href',
              this.lastItemColor
            )
          } else {
            d3.select('#' + this.lastItemId).attr('style', this.lastItemColor)
          }
        }
        console.log(id)
        console.log(this.lastItemColor)
        this.lastItemId = id
        if (id.charAt(0) === 'P' && id.charAt(1) === 'R') {
          this.lastItemColor = d3.select('#' + id).attr('xlink:href')
        } else {
          this.lastItemColor = d3.select('#' + id).attr('style')
        }
        // this.lastItemColor = d3.select('#' + id).attr('xlink:href')
        if (id.charAt(0) === 'P' && id.charAt(1) === 'R') {
          d3.select('#' + id).attr('xlink:href', this.imgPath.crossSGClick)
        } else {
          d3.select('#' + id).attr(
            'style',
            'fill:#BEDF76;stroke-width:1;stroke:rgb(0,0,0)'
          )
        }
      } else {
        if (this.lastItemId !== undefined && this.lastItemColor !== undefined) {
          d3.select('#' + this.lastItemId).attr('style', this.lastItemColor)
        }
        this.lastItemId = id
        this.lastItemColor = d3.select('#' + id).attr('style')
        d3.select('#' + id).attr(
          'style',
          'fill:#BEDF76;stroke-width:1;stroke:rgb(0,0,0)'
        )
      }
    },
    async bridgeComponentClickObject (
      sectorID,
      sectorNo,
      componentID,
      componentNo,
      text,
      back,
      action
    ) {
      const result = {
        sectorID: sectorID,
        sectorNo: sectorNo,
        componentID: componentID,
        componentNo: componentNo,
        text: text,
        back: back,
        action: action
      }
      this.$emit('clicked', result)
    },
    async initZoom () {
      console.log('init zoom')
      const self = this
      const opacity = 0.3

      console.log(this.bridgeType)

      const g = d3.select('g')

      let imageDistance = 600 + 32.7 * this.bridgePartCount
      let styleColor = 'fill:rgb(216,216,216);stroke-width:1;stroke:rgb(0,0,0)'
      const selectColor = 'fill:rgb(255,69,0);stroke-width:1;stroke:rgb(0,0,0)'

      this.bridgeWidth = 32.7 * this.bridgePartCount

      if (
        this.bridgeFootCount > 9 &&
        this.bridgeType.charAt(3) === 'P' &&
        this.bridgeType.charAt(4) === 'B'
      ) {
        this.bridgeWidth = 32.7 * this.bridgePartCount * 3
      }

      if (
        this.bridgeType.charAt(0) === 'B' &&
        this.bridgeType.charAt(1) === 'G'
      ) {
        g.append('rect')
          .attr('x', 400)
          .attr('y', 180)
          .attr('height', 30)
          .attr('width', this.bridgeWidth)
          .attr(
            'style',
            'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
          )
          .attr('opacity', opacity)
        g.append('rect')
          .attr('x', 400)
          .attr('y', 240)
          .attr('height', 5)
          .attr('width', this.bridgeWidth)
          .attr(
            'style',
            'fill:rgb(64,114,196);stroke-width:1;stroke:rgb(0,0,0)'
          )
          .attr('opacity', opacity)
        if (this.isIndept) {
          styleColor = 'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
          for (let x = 0; x < this.indeptSectorComplete.CB.items.length; x++) {
            if (this.indeptSectorComplete.CB.items[x] === '1') {
              styleColor = selectColor
            }
          }
        } else {
          styleColor = 'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
        }
        g.append('rect')
          .attr('id', function () {
            return 'CB1'
          })
          .attr('x', 400)
          .attr('y', 245)
          .attr('height', 60)
          .attr('width', this.bridgeWidth)
          .attr('style', styleColor)
          .attr('class', 'hoverItem')
          .on('click', function () {
            self.bridgeComponentClickObject(
              self.bridgeType.charAt(3),
              self.bridgePartIndex,
              'CB',
              1,
              'CB1',
              false,
              true
            )
            self.changeColor('CB1')
          })
        g.append('text')
          .text('CB1')
          .attr('x', 400 + this.bridgeWidth / 2 - 15)
          .attr('y', 280)
          .attr('class', 'textLayer')
          .attr('class', 'hoverItem')
          .on('click', function () {
            self.bridgeComponentClickObject(
              self.bridgeType.charAt(3),
              self.bridgePartIndex,
              'CB',
              1,
              'CB1',
              false,
              true
            )
            self.changeColor('CB1')
          })
        let bridgeStaticImage = 20
        if (
          this.bridgeFootCount > 9 &&
          this.bridgeType.charAt(3) === 'P' &&
          this.bridgeType.charAt(4) === 'B'
        ) {
          bridgeStaticImage = bridgeStaticImage * 3
        }
        for (let i = 0; i < bridgeStaticImage; i++) {
          g.append('svg:image')
            .attr('height', 30)
            .attr('x', 400 + i * 32.7)
            .attr('y', 210)
            .attr('xlink:href', this.imgPath.crossBG)
            .attr('opacity', opacity)
        }
        g.append('svg:image')
          .attr('height', 50)
          .attr('x', 400)
          .attr('y', 130)
          .attr('xlink:href', this.imgPath.crossRLeft)
          .attr('opacity', opacity)
        g.append('svg:image')
          .attr('height', 50)
          .attr('x', 357 + this.bridgeWidth)
          .attr('y', 130)
          .attr('xlink:href', this.imgPath.crossRRight)
          .attr('opacity', opacity)
        if (this.bridgeType.charAt(3) === 'A') {
          this.drawA(g, opacity)
        } else if (
          this.bridgeType.charAt(3) === 'P' &&
          this.bridgeType.charAt(4) === 'W'
        ) {
          this.drawPW(g, opacity)
        } else if (
          this.bridgeType.charAt(3) === 'P' &&
          this.bridgeType.charAt(4) === 'B'
        ) {
          this.drawPB(g, opacity)
        }
      } else if (
        this.bridgeType.charAt(0) === 'I' &&
        this.bridgeType.charAt(1) === 'G'
      ) {
        g.append('rect')
          .attr('x', 400)
          .attr('y', 180)
          .attr('height', 30)
          .attr('width', this.bridgeWidth)
          .attr(
            'style',
            'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
          )
          .attr('opacity', opacity)
        if (this.isIndept) {
          styleColor = 'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
          for (let x = 0; x < this.indeptSectorComplete.CB.items.length; x++) {
            if (this.indeptSectorComplete.CB.items[x] === '1') {
              styleColor = selectColor
            }
          }
        } else {
          styleColor = 'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
        }
        g.append('rect')
          .attr('id', function () {
            return 'CB1'
          })
          .attr('x', 400)
          .attr('y', 245)
          .attr('height', 60)
          .attr('width', this.bridgeWidth)
          .attr('style', styleColor)
          .attr('class', 'hoverItem')
          .on('click', function () {
            self.bridgeComponentClickObject(
              self.bridgeType.charAt(3),
              self.bridgePartIndex,
              'CB',
              1,
              'CB1',
              false,
              true
            )
            self.changeColor('CB1')
          })
        g.append('text')
          .text('CB1')
          .attr('x', 400 + this.bridgeWidth / 2 - 15)
          .attr('y', 280)
          .attr('class', 'textLayer')
          .attr('class', 'hoverItem')
          .on('click', function () {
            self.bridgeComponentClickObject(
              self.bridgeType.charAt(3),
              self.bridgePartIndex,
              'CB',
              1,
              'CB1',
              false,
              true
            )
            self.changeColor('CB1')
          })
        let bridgeStaticImage = 5
        if (
          this.bridgeFootCount > 9 &&
          this.bridgeType.charAt(3) === 'P' &&
          this.bridgeType.charAt(4) === 'B'
        ) {
          bridgeStaticImage = bridgeStaticImage * 3 - 2
          for (let i = 0; i < bridgeStaticImage; i++) {
            g.append('svg:image')
              .attr('height', 35)
              .attr('x', 400 + i * 40.5 * 4)
              .attr('y', 210)
              .attr('xlink:href', this.imgPath.crossIG)
              .attr('opacity', opacity)
          }
        } else {
          for (let i = 0; i < bridgeStaticImage; i++) {
            g.append('svg:image')
              .attr('height', 35)
              .attr('x', 400 + i * 39.9 * 4)
              .attr('y', 210)
              .attr('xlink:href', this.imgPath.crossIG)
              .attr('opacity', opacity)
          }
        }
        g.append('svg:image')
          .attr('height', 50)
          .attr('x', 400)
          .attr('y', 130)
          .attr('xlink:href', this.imgPath.crossRLeft)
          .attr('opacity', opacity)
        g.append('svg:image')
          .attr('height', 50)
          .attr('x', 357 + this.bridgeWidth)
          .attr('y', 130)
          .attr('xlink:href', this.imgPath.crossRRight)
          .attr('opacity', opacity)

        if (this.bridgeType.charAt(3) === 'A') {
          this.drawA(g, opacity)
        } else if (
          this.bridgeType.charAt(3) === 'P' &&
          this.bridgeType.charAt(4) === 'W'
        ) {
          this.drawPW(g, opacity)
        } else if (
          this.bridgeType.charAt(3) === 'P' &&
          this.bridgeType.charAt(4) === 'B'
        ) {
          this.drawPB(g, opacity)
        }
      } else if (
        this.bridgeType.charAt(0) === 'M' &&
        this.bridgeType.charAt(1) === 'B'
      ) {
        g.append('rect')
          .attr('x', 400)
          .attr('y', 180)
          .attr('height', 30)
          .attr('width', this.bridgeWidth)
          .attr(
            'style',
            'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
          )
          .attr('opacity', opacity)
        if (this.isIndept) {
          styleColor = 'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
          for (let x = 0; x < this.indeptSectorComplete.CB.items.length; x++) {
            if (this.indeptSectorComplete.CB.items[x] === '1') {
              styleColor = selectColor
            }
          }
        } else {
          styleColor = 'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
        }
        g.append('rect')
          .attr('id', function () {
            return 'CB1'
          })
          .attr('x', 400)
          .attr('y', 245)
          .attr('height', 60)
          .attr('width', this.bridgeWidth)
          .attr('style', styleColor)
          .attr('class', 'hoverItem')
          .on('click', function () {
            self.bridgeComponentClickObject(
              self.bridgeType.charAt(3),
              self.bridgePartIndex,
              'CB',
              1,
              'CB1',
              false,
              true
            )
            self.changeColor('CB1')
          })
        g.append('text')
          .text('CB1')
          .attr('x', 400 + this.bridgeWidth / 2 - 15)
          .attr('y', 280)
          .attr('class', 'textLayer')
          .attr('class', 'hoverItem')
          .on('click', function () {
            self.bridgeComponentClickObject(
              self.bridgeType.charAt(3),
              self.bridgePartIndex,
              'CB',
              1,
              'CB1',
              false,
              true
            )
            self.changeColor('CB1')
          })
        let bridgeStaticImage = 19
        if (
          this.bridgeFootCount > 9 &&
          this.bridgeType.charAt(3) === 'P' &&
          this.bridgeType.charAt(4) === 'B'
        ) {
          bridgeStaticImage = bridgeStaticImage * 3
        }
        for (let i = 0; i < bridgeStaticImage; i++) {
          g.append('svg:image')
            .attr('height', 35)
            .attr('x', 400 + i * 34.4)
            .attr('y', 210)
            .attr('xlink:href', this.imgPath.crossMB)
            .attr('opacity', opacity)
        }
        g.append('svg:image')
          .attr('height', 50)
          .attr('x', 400)
          .attr('y', 130)
          .attr('xlink:href', this.imgPath.crossRLeft)
          .attr('opacity', opacity)
        g.append('svg:image')
          .attr('height', 50)
          .attr('x', 357 + this.bridgeWidth)
          .attr('y', 130)
          .attr('xlink:href', this.imgPath.crossRRight)
          .attr('opacity', opacity)

        if (this.bridgeType.charAt(3) === 'A') {
          this.drawA(g, opacity)
        } else if (
          this.bridgeType.charAt(3) === 'P' &&
          this.bridgeType.charAt(4) === 'W'
        ) {
          this.drawPW(g, opacity)
        } else if (
          this.bridgeType.charAt(3) === 'P' &&
          this.bridgeType.charAt(4) === 'B'
        ) {
          this.drawPB(g, opacity)
        }
      } else if (
        this.bridgeType.charAt(0) === 'P' &&
        this.bridgeType.charAt(1) === 'G'
      ) {
        g.append('rect')
          .attr('x', 400)
          .attr('y', 180)
          .attr('height', 30)
          .attr('width', this.bridgeWidth)
          .attr(
            'style',
            'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
          )
          .attr('opacity', opacity)
        if (this.isIndept) {
          styleColor = 'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
          for (let x = 0; x < this.indeptSectorComplete.CB.items.length; x++) {
            if (this.indeptSectorComplete.CB.items[x] === '1') {
              styleColor = selectColor
            }
          }
        } else {
          styleColor = 'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
        }
        g.append('rect')
          .attr('id', function () {
            return 'CB1'
          })
          .attr('x', 400)
          .attr('y', 245)
          .attr('height', 60)
          .attr('width', this.bridgeWidth)
          .attr('style', styleColor)
          .attr('class', 'hoverItem')
          .on('click', function () {
            self.bridgeComponentClickObject(
              self.bridgeType.charAt(3),
              self.bridgePartIndex,
              'CB',
              1,
              'CB1',
              false,
              true
            )
            self.changeColor('CB1')
          })
        g.append('text')
          .text('CB1')
          .attr('x', 400 + this.bridgeWidth / 2 - 15)
          .attr('y', 280)
          .attr('class', 'textLayer')
          .attr('class', 'hoverItem')
          .on('click', function () {
            self.bridgeComponentClickObject(
              self.bridgeType.charAt(3),
              self.bridgePartIndex,
              'CB',
              1,
              'CB1',
              false,
              true
            )
            self.changeColor('CB1')
          })
        let bridgeStaticImage = 9
        if (
          this.bridgeFootCount > 9 &&
          this.bridgeType.charAt(3) === 'P' &&
          this.bridgeType.charAt(4) === 'B'
        ) {
          bridgeStaticImage = bridgeStaticImage * 3
        }
        for (let i = 0; i < bridgeStaticImage; i++) {
          g.append('svg:image')
            .attr('height', 35)
            .attr('x', 400 + i * 72.4)
            .attr('y', 210)
            .attr('xlink:href', this.imgPath.crossPG)
            .attr('opacity', opacity)
        }
        g.append('svg:image')
          .attr('height', 50)
          .attr('x', 400)
          .attr('y', 130)
          .attr('xlink:href', this.imgPath.crossRLeft)
          .attr('opacity', opacity)
        g.append('svg:image')
          .attr('height', 50)
          .attr('x', 357 + this.bridgeWidth)
          .attr('y', 130)
          .attr('xlink:href', this.imgPath.crossRRight)
          .attr('opacity', opacity)

        if (this.bridgeType.charAt(3) === 'A') {
          this.drawA(g, opacity)
        } else if (
          this.bridgeType.charAt(3) === 'P' &&
          this.bridgeType.charAt(4) === 'W'
        ) {
          this.drawPW(g, opacity)
        } else if (
          this.bridgeType.charAt(3) === 'P' &&
          this.bridgeType.charAt(4) === 'B'
        ) {
          this.drawPB(g, opacity)
        }
      } else if (
        this.bridgeType.charAt(0) === 'S' &&
        this.bridgeType.charAt(1) === 'T'
      ) {
        g.append('rect')
          .attr('x', 400)
          .attr('y', 180)
          .attr('height', 30)
          .attr('width', this.bridgeWidth)
          .attr(
            'style',
            'fill:url(#diagonalHatch);stroke-width:1;stroke:rgb(0,0,0)'
          )
          .attr('opacity', opacity)
        if (this.isIndept) {
          styleColor = 'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
          for (let x = 0; x < this.indeptSectorComplete.CB.items.length; x++) {
            if (this.indeptSectorComplete.CB.items[x] === '1') {
              styleColor = selectColor
            }
          }
        } else {
          styleColor = 'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
        }
        g.append('rect')
          .attr('id', function () {
            return 'CB1'
          })
          .attr('x', 400)
          .attr('y', 245)
          .attr('height', 60)
          .attr('width', this.bridgeWidth)
          .attr('style', styleColor)
          .attr('class', 'hoverItem')
          .on('click', function () {
            self.bridgeComponentClickObject(
              self.bridgeType.charAt(3),
              self.bridgePartIndex,
              'CB',
              1,
              'CB1',
              false,
              true
            )
            self.changeColor('CB1')
          })
        g.append('text')
          .text('CB1')
          .attr('x', 400 + this.bridgeWidth / 2 - 15)
          .attr('y', 280)
          .attr('class', 'textLayer')
          .attr('class', 'hoverItem')
          .on('click', function () {
            self.bridgeComponentClickObject(
              self.bridgeType.charAt(3),
              self.bridgePartIndex,
              'CB',
              1,
              'CB1',
              false,
              true
            )
            self.changeColor('CB1')
          })
        g.append('rect')
          .attr('x', 400)
          .attr('y', 210)
          .attr('height', 30)
          .attr('width', this.bridgeWidth)
          .attr(
            'style',
            'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
          )
          .attr('opacity', opacity)
        g.append('rect')
          .attr('x', 400)
          .attr('y', 240)
          .attr('height', 5)
          .attr('width', this.bridgeWidth)
          .attr(
            'style',
            'fill:rgb(68,114,196);stroke-width:1;stroke:rgb(0,0,0)'
          )
          .attr('opacity', opacity)
        g.append('text')
          .text('Slab')
          .attr('x', 400 + this.bridgeWidth / 2 - 15)
          .attr('y', 230)
          .attr('class', 'textLayer')
          .attr('opacity', opacity)
        g.append('svg:image')
          .attr('height', 50)
          .attr('x', 400)
          .attr('y', 130)
          .attr('xlink:href', this.imgPath.crossRLeft)
          .attr('opacity', opacity)
        g.append('svg:image')
          .attr('height', 50)
          .attr('x', 357 + this.bridgeWidth)
          .attr('y', 130)
          .attr('xlink:href', this.imgPath.crossRRight)
          .attr('opacity', opacity)

        if (this.bridgeType.charAt(3) === 'A') {
          this.drawA(g, opacity)
        } else if (
          this.bridgeType.charAt(3) === 'P' &&
          this.bridgeType.charAt(4) === 'W'
        ) {
          this.drawPW(g, opacity)
        } else if (
          this.bridgeType.charAt(3) === 'P' &&
          this.bridgeType.charAt(4) === 'B'
        ) {
          this.drawPB(g, opacity)
        }
      } else if (
        this.bridgeType.charAt(0) === 'U' &&
        this.bridgeType.charAt(1) === 'G'
      ) {
        g.append('rect')
          .attr('x', 400)
          .attr('y', 180)
          .attr('height', 30)
          .attr('width', this.bridgeWidth)
          .attr(
            'style',
            'fill:url(#diagonalHatch);stroke-width:1;stroke:rgb(0,0,0)'
          )
          .attr('opacity', opacity)
        if (this.isIndept) {
          styleColor = 'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
          for (let x = 0; x < this.indeptSectorComplete.CB.items.length; x++) {
            if (this.indeptSectorComplete.CB.items[x] === '1') {
              styleColor = selectColor
            }
          }
        } else {
          styleColor = 'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
        }
        g.append('rect')
          .attr('id', function () {
            return 'CB1'
          })
          .attr('x', 400)
          .attr('y', 245)
          .attr('height', 60)
          .attr('width', this.bridgeWidth)
          .attr('style', styleColor)
          .attr('class', 'hoverItem')
          .on('click', function () {
            self.bridgeComponentClickObject(
              self.bridgeType.charAt(3),
              self.bridgePartIndex,
              'CB',
              1,
              'CB1',
              false,
              true
            )
            self.changeColor('CB1')
          })
        g.append('text')
          .text('CB1')
          .attr('x', 400 + this.bridgeWidth / 2 - 15)
          .attr('y', 280)
          .attr('class', 'textLayer')
          .attr('class', 'hoverItem')
          // .on('click', function () { self.bridgeComponentClick('CB1') })
          .on('click', function () {
            self.bridgeComponentClickObject(
              self.bridgeType.charAt(3),
              self.bridgePartIndex,
              'CB',
              1,
              'CB1',
              false,
              true
            )
            self.changeColor('CB1')
          })
        let bridgeStaticImage = 9
        if (
          this.bridgeFootCount > 9 &&
          this.bridgeType.charAt(3) === 'P' &&
          this.bridgeType.charAt(4) === 'B'
        ) {
          bridgeStaticImage = bridgeStaticImage * 3
        }
        for (let i = 0; i < bridgeStaticImage; i++) {
          g.append('svg:image')
            .attr('height', 39)
            .attr('x', 410 + i * 72.4)
            .attr('y', 208)
            .attr('xlink:href', this.imgPath.crossUG)
            .attr('opacity', opacity)
        }
        g.append('svg:image')
          .attr('height', 50)
          .attr('x', 400)
          .attr('y', 130)
          .attr('xlink:href', this.imgPath.crossRLeft)
          .attr('opacity', opacity)
        g.append('svg:image')
          .attr('height', 50)
          .attr('x', 357 + this.bridgeWidth)
          .attr('y', 130)
          .attr('xlink:href', this.imgPath.crossRRight)
          .attr('opacity', opacity)
        console.log(this.bridgeType)
        if (this.bridgeType.charAt(3) === 'A') {
          this.drawA(g, opacity)
        } else if (
          this.bridgeType.charAt(3) === 'P' &&
          this.bridgeType.charAt(4) === 'W'
        ) {
          this.drawPW(g, opacity)
        } else if (
          this.bridgeType.charAt(3) === 'P' &&
          this.bridgeType.charAt(4) === 'B'
        ) {
          this.drawPB(g, opacity)
        }
      } else if (
        (this.bridgeType.charAt(0) === 'S' &&
          this.bridgeType.charAt(1) === 'G') ||
        (this.bridgeType.charAt(0) === 'P' && this.bridgeType.charAt(1) === 'C')
      ) {
        if (this.bridgeType.charAt(3) === 'A') {
          g.append('rect')
            .attr('x', 400)
            .attr('y', 180)
            .attr('height', 30)
            .attr('width', 32.7 * this.bridgePartCount)
            .attr(
              'style',
              'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
            )
            .attr('opacity', opacity)
          g.append('svg:image')
            .attr('height', 50)
            .attr('x', 400)
            .attr('y', 130)
            .attr('xlink:href', this.imgPath.crossRLeft)
            .attr('opacity', opacity)
          g.append('svg:image')
            .attr('height', 50)
            .attr('x', 400 + 32.7 * (this.bridgePartCount - 1) - 10)
            .attr('y', 130)
            .attr('xlink:href', this.imgPath.crossRRight)
            .attr('opacity', opacity)
          this.drawA(g, opacity)
        } else if (
          this.bridgeType.charAt(3) === 'P' &&
          this.bridgeType.charAt(4) === 'W'
        ) {
          this.drawPW(g, opacity)
        } else if (
          (this.bridgeType.charAt(3) === 'P' &&
            this.bridgeType.charAt(4) === 'B') ||
          (this.bridgeType.charAt(0) === 'P' &&
            this.bridgeType.charAt(1) === 'C')
        ) {
          imageDistance = 600 + 32.7 * this.bridgePartCount
          g.append('rect')
            .attr('x', 400)
            .attr('y', 180)
            .attr('height', 30)
            .attr('width', 32.7 * 12.85 * this.bridgeFootCount)
            .attr(
              'style',
              'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
            )
            .attr('opacity', opacity)
          g.append('svg:image')
            .attr('height', 50)
            .attr('x', 400)
            .attr('y', 130)
            .attr('xlink:href', this.imgPath.crossRLeft)
            .attr('opacity', opacity)
          g.append('svg:image')
            .attr('height', 50)
            .attr('x', 357 + 32.7 * 12.85 * this.bridgeFootCount)
            .attr('y', 130)
            .attr('xlink:href', this.imgPath.crossRRight)
            .attr('opacity', opacity)
          for (let i = 0; i < this.bridgeFootCount; i++) {
            if (this.isIndept) {
              styleColor = this.imgPath.crossSG
              try {
                for (
                  let x = 0;
                  x < this.indeptSectorComplete.PR.items.length;
                  x++
                ) {
                  if (
                    this.indeptSectorComplete.PR.items[x] === (i + 1).toString()
                  ) {
                    styleColor = this.imgPath.crossSGFinish
                  }
                }
              } catch (err) {
                console.log(err)
              }
            } else {
              styleColor = this.imgPath.crossSG
            }
            if (
              this.bridgeType.charAt(0) === 'S' &&
              this.bridgeType.charAt(1) === 'G'
            ) {
              g.append('svg:image')
                .attr('id', function () {
                  return `PR${i + 1}`
                })
                .attr('height', 300)
                .attr('x', 400 + i * 39.9 * 10.5 - 9)
                .attr('y', 201)
                .attr('xlink:href', styleColor)
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeComponentClickObject(
                    'P',
                    self.bridgePartIndex,
                    'PR',
                    i + 1,
                    'PR' + (i + 1).toString(),
                    false,
                    true
                  )
                  self.changeColor('PR' + (i + 1).toString())
                })
              g.append('text')
                .text('PR' + (i + 1).toString() + '-BR1')
                .attr('x', 400 + i * 39.9 * 10.5 - 9 + 60)
                .attr('y', 270)
              g.append('rect')
                .attr('x', 400 + i * 39.9 * 10.5 - 9 + 165)
                .attr('y', 260)
                .attr('height', 6)
                .attr('width', 30)
                .attr(
                  'style',
                  'fill:rgb(68,114,196);stroke-width:1;stroke:rgb(0,0,0)'
                )
              g.append('text')
                .text('PR' + (i + 1).toString() + '-BR2')
                .attr('x', 400 + i * 39.9 * 10.5 - 9 + 310)
                .attr('y', 270)
              g.append('rect')
                .attr('x', 400 + i * 39.9 * 10.5 - 9 + 245)
                .attr('y', 260)
                .attr('height', 6)
                .attr('width', 30)
                .attr(
                  'style',
                  'fill:rgb(68,114,196);stroke-width:1;stroke:rgb(0,0,0)'
                )
            } else {
              if (this.isIndept) {
                styleColor =
                  'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
                for (
                  let x = 0;
                  x < this.indeptSectorComplete.PR.items.length;
                  x++
                ) {
                  if (
                    this.indeptSectorComplete.PR.items[x] === (i + 1).toString()
                  ) {
                    styleColor = selectColor
                  }
                }
              } else {
                styleColor =
                  'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
              }
              g.append('rect')
                .attr('id', function () {
                  return 'PR' + (i + 1).toString()
                })
                .attr('x', 600 + i * 39.9 * 10.5 - 9)
                .attr('y', 210)
                .attr('height', 290)
                .attr('width', 40)
                .attr('style', styleColor)
                .attr('class', 'hoverItem')
                .on('click', function () {
                  self.bridgeComponentClickObject(
                    'P',
                    self.bridgePartIndex,
                    'PR',
                    i + 1,
                    'PR' + (i + 1).toString(),
                    false,
                    true
                  )
                  self.changeColor('PR' + (i + 1).toString())
                })
            }
            g.append('text')
              .text('PR' + (i + 1).toString())
              .attr('x', 400 + i * 39.9 * 10.5 - 9 + 207)
              .attr('y', 350)
              .attr('class', 'hoverItem')
              .on('click', function () {
                self.bridgeComponentClickObject(
                  'P',
                  self.bridgePartIndex,
                  'PR',
                  i + 1,
                  'PR' + (i + 1).toString(),
                  false,
                  true
                )
                self.changeColor('PR' + (i + 1).toString())
              })
            if (this.isIndept) {
              styleColor =
                'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
              for (
                let x = 0;
                x < this.indeptSectorComplete.FT.items.length;
                x++
              ) {
                if (
                  this.indeptSectorComplete.FT.items[x] === (i + 1).toString()
                ) {
                  styleColor = selectColor
                }
              }
            } else {
              styleColor =
                'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
            }
            const checkallow = await this.$store.dispatch(
              'bridgeDetail/checkIsAllowed',
              {
                componentId: 'FT',
                inspectionType: this.inspectionType
              }
            )
            g.append('rect')
              .attr('id', function () {
                return `FT${i + 1}`
              })
              .attr('x', 324 + i * 39.9 * 10.5 - 9 + 245)
              .attr('y', 492)
              .attr('height', 35)
              .attr('width', 100)
              .attr('style', styleColor)
              .attr('class', 'hoverItem')
              .on('click', function () {
                self.bridgeComponentClickObject(
                  'P',
                  self.bridgePartIndex,
                  'FT',
                  i + 1,
                  'FT' + (i + 1).toString(),
                  false,
                  true,
                  checkallow
                )
                self.changeColor('FT' + (i + 1).toString(), checkallow)
              })
            g.append('text')
              .text('FT' + (i + 1).toString())
              .attr('x', 358 + i * 39.9 * 10.5 - 9 + 245)
              .attr('y', 513)
              .attr('class', 'hoverItem')
              .on('click', function () {
                self.bridgeComponentClickObject(
                  'P',
                  self.bridgePartIndex,
                  'FT',
                  i + 1,
                  'FT' + (i + 1).toString(),
                  false,
                  true,
                  checkallow
                )
                self.changeColor('FT' + (i + 1).toString(), checkallow)
              })
          }
          g.append('text')
            .text('P' + this.bridgePartIndex.toString())
            .attr('x', imageDistance / 2 + 90 - this.bridgeFootCount * 15)
            .attr('y', 100)
            .attr('opacity', opacity)
        }
      }
      if (this.disableDirection === true) {
        if (this.direction === 'right') {
          g.append('line')
            .style('stroke', 'black')
            .style('stroke-width', 3)
            .attr('x1', (imageDistance + 150) / 3)
            .attr('y1', 50)
            .attr('x2', ((imageDistance + 150) * 2) / 3)
            .attr('y2', 50)
            .attr('marker-end', 'url(#triangle_fwd)')
        } else if (this.direction === 'left') {
          g.append('line')
            .style('stroke', 'black')
            .style('stroke-width', 3)
            .attr('x1', (imageDistance + 150) / 3)
            .attr('y1', 50)
            .attr('x2', ((imageDistance + 150) * 2) / 3)
            .attr('y2', 50)
            .attr('marker-start', 'url(#triangle_bwd)')
        }

        g.append('text')
          .text('ทิศทางจราจร')
          .attr('x', imageDistance / 2)
          .attr('y', 30)
          .attr('class', 'textLayer')
        g.append('text')
          .text('กม. น้อย')
          .attr('x', 100)
          .attr('y', 275)
          .attr('class', 'textLayer')
        g.append('text')
          .text('กม. มาก')
          .attr('x', imageDistance)
          .attr('y', 274)
          .attr('class', 'textLayer')
        g.append('text')
          .text('ไป ' + this.startLocation)
          .attr('x', 100)
          .attr('y', 210)
          .attr('class', 'textLayer')
        g.append('text')
          .text('ไป ' + this.endLocation)
          .attr('x', imageDistance)
          .attr('y', 210)
          .attr('class', 'textLayer')
        g.append('line')
          .style('stroke', 'black')
          .style('stroke-width', 3)
          .attr('x1', 100)
          .attr('y1', 180)
          .attr('x2', 250)
          .attr('y2', 180)
          .attr('marker-start', 'url(#triangle_bwd)')
        g.append('line')
          .style('stroke', 'black')
          .style('stroke-width', 3)
          .attr('x1', imageDistance)
          .attr('y1', 180)
          .attr('x2', imageDistance + 150)
          .attr('y2', 180)
          .attr('marker-end', 'url(#triangle_fwd)')
      }

      g.append('svg:defs')
        .append('svg:marker')
        .attr('id', 'triangle_fwd')
        .attr('refX', 2)
        .attr('refY', 2)
        .attr('markerWidth', 30)
        .attr('markerHeight', 30)
        .attr('orient', '0deg')
        .append('path')
        .attr('d', 'M 0 0 4 2 0 4 1 2')
        .style('fill', 'black')
      g.append('svg:defs')
        .append('svg:marker')
        .attr('id', 'triangle_bwd')
        .attr('refX', 2)
        .attr('refY', 2)
        .attr('markerWidth', 30)
        .attr('markerHeight', 30)
        .attr('orient', '180deg')
        .append('path')
        .attr('d', 'M 0 0 4 2 0 4 1 2')
        .style('fill', 'black')
      g.append('svg:defs')
        .append('svg:pattern')
        .attr('id', 'diagonalHatch')
        .attr('patternUnits', 'userSpaceOnUse')
        .attr('patternTransform', 'rotate(45)')
        .attr('width', 9.5)
        .attr('height', 9.5)
        .append('line')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', 9.5)
        .attr('stroke-width', '1')
        .style('stroke', 'black')

      const zoom = d3.zoom().scaleExtent([0.05, 5]).on('zoom', this.handleZoom)
      const transform = this.zoomFit(g)
      d3.select('#bridge').call(zoom).call(zoom.transform, transform)
      d3.select('#bridge').on('dblclick.zoom', null)

      if (this.selectedPart !== false && this.selectedPart) {
        let splitPart = this.selectedPart.split('-')
        splitPart = splitPart.slice(1)
        this.changeColor(splitPart.join('-'))
      }
      var all = document.getElementsByClassName('hoverItem')
      console.log(all)
    },
    async drawA (g, opacity) {
      const self = this
      let styleColor = 'fill:rgb(216,216,216);stroke-width:1;stroke:rgb(0,0,0)'
      if (this.isIndept) {
        styleColor = 'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
        for (let x = 0; x < this.indeptSectorComplete.SP.items.length; x++) {
          if (this.indeptSectorComplete.SP.items[x] === '1') {
            styleColor = 'fill:rgb(255,69,0);stroke-width:1;stroke:rgb(0,0,0)'
          }
        }
      } else {
        styleColor = 'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
      }
      let checkallow = await this.$store.dispatch(
        'bridgeDetail/checkIsAllowed',
        {
          componentId: 'SP',
          inspectionType: this.inspectionType
        }
      )
      g.append('polygon')
        .attr('id', function () {
          return 'SP1'
        })
        .attr(
          'points',
          '400,305 ' +
            (400 + 32.7 * this.bridgePartCount).toString() +
            ',305 ' +
            (400 + 32.7 * this.bridgePartCount + 100).toString() +
            ',505 300,505'
        )
        .attr('style', styleColor)
        .attr('class', checkallow ? 'hoverItem' : null)
        .on(
          'click',
          checkallow
            ? function () {
              self.bridgeComponentClickObject(
                'A',
                self.bridgePartIndex,
                'SP',
                1,
                'SP1',
                false,
                true
              )
              self.changeColor('SP1')
            }
            : null
        )
      if (this.isIndept) {
        styleColor = 'fill:rgb(173,173,173);stroke-width:1;stroke:rgb(0,0,0)'
        for (let x = 0; x < this.indeptSectorComplete.WW.items.length; x++) {
          if (this.indeptSectorComplete.WW.items[x] === '1') {
            styleColor = 'fill:rgb(255,69,0);stroke-width:1;stroke:rgb(0,0,0)'
          }
        }
      } else {
        styleColor = 'fill:rgb(173,173,173);stroke-width:1;stroke:rgb(0,0,0)'
      }
      g.append('polygon')
        .attr('id', function () {
          return 'WW1'
        })
        .attr('points', '350,180 400,180 400,305 350,405 300,405 300,280')
        .attr('style', styleColor)
        .attr('class', 'hoverItem')
        .on('click', function () {
          self.bridgeComponentClickObject(
            'A',
            self.bridgePartIndex,
            'WW',
            1,
            'WW1',
            false,
            true
          )
          self.changeColor('WW1')
        })
      if (this.isIndept) {
        styleColor = 'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
        for (let x = 0; x < this.indeptSectorComplete.SP.items.length; x++) {
          if (this.indeptSectorComplete.SP.items[x] === '1') {
            styleColor = 'fill:rgb(255,69,0);stroke-width:1;stroke:rgb(0,0,0)'
          }
        }
      } else {
        styleColor = 'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
      }
      checkallow = await this.$store.dispatch('bridgeDetail/checkIsAllowed', {
        componentId: 'SP',
        inspectionType: this.inspectionType
      })
      g.append('polygon')
        .attr('points', '400,305 400,405 350,405')
        .attr('style', styleColor)
        .attr('class', checkallow ? 'hoverItem' : null)
        .on(
          'click',
          checkallow
            ? function () {
              self.bridgeComponentClickObject(
                'A',
                self.bridgePartIndex,
                'SP',
                1,
                'SP1',
                false,
                true
              )
              self.changeColor('SP1')
            }
            : null
        )
      if (this.isIndept) {
        styleColor = 'fill:rgb(173,173,173);stroke-width:1;stroke:rgb(0,0,0)'
        for (let x = 0; x < this.indeptSectorComplete.WW.items.length; x++) {
          if (this.indeptSectorComplete.WW.items[x] === '2') {
            styleColor = 'fill:rgb(255,69,0);stroke-width:1;stroke:rgb(0,0,0)'
          }
        }
      } else {
        styleColor = 'fill:rgb(173,173,173);stroke-width:1;stroke:rgb(0,0,0)'
      }
      g.append('polygon')
        .attr('id', function () {
          return 'WW2'
        })
        .attr(
          'points',
          (400 + 32.7 * this.bridgePartCount).toString() +
            ',180 ' +
            (400 + 32.7 * this.bridgePartCount + 50).toString() +
            ',180 ' +
            (400 + 32.7 * this.bridgePartCount + 100).toString() +
            ',280 ' +
            (400 + 32.7 * this.bridgePartCount + 100).toString() +
            ',405 ' +
            (400 + 32.7 * this.bridgePartCount + 50).toString() +
            ',405 ' +
            (400 + 32.7 * this.bridgePartCount).toString() +
            ',305'
        )
        .attr('style', styleColor)
        .attr('class', 'hoverItem')
        .on('click', function () {
          self.bridgeComponentClickObject(
            'A',
            self.bridgePartIndex,
            'WW',
            2,
            'WW2',
            false,
            true
          )
          self.changeColor('WW2')
        })
      if (this.isIndept) {
        styleColor = 'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
        for (let x = 0; x < this.indeptSectorComplete.SP.items.length; x++) {
          if (this.indeptSectorComplete.SP.items[x] === '1') {
            styleColor = 'fill:rgb(255,69,0);stroke-width:1;stroke:rgb(0,0,0)'
          }
        }
      } else {
        styleColor = 'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
      }
      checkallow = await this.$store.dispatch('bridgeDetail/checkIsAllowed', {
        componentId: 'SP',
        inspectionType: this.inspectionType
      })
      g.append('polygon')
        .attr(
          'points',
          (400 + 32.7 * this.bridgePartCount).toString() +
            ',305 ' +
            (400 + 32.7 * this.bridgePartCount).toString() +
            ',405 ' +
            (400 + 32.7 * this.bridgePartCount + 50).toString() +
            ',405'
        )
        .attr('style', styleColor)
        .attr('class', checkallow ? 'hoverItem' : null)
        .on(
          'click',
          checkallow
            ? function () {
              self.bridgeComponentClickObject(
                'A',
                self.bridgePartIndex,
                'SP',
                1,
                'SP1',
                false,
                true
              )
              self.changeColor('SP1')
            }
            : null
        )
      g.append('text')
        .text('WW1')
        .attr('x', 330)
        .attr('y', 300)
        .attr('class', 'textLayer')
        .attr('class', 'hoverItem')
        .on('click', function () {
          self.bridgeComponentClickObject(
            'A',
            self.bridgePartIndex,
            'WW',
            1,
            'WW1',
            false,
            true
          )
          self.changeColor('WW1')
        })
      g.append('text')
        .text('WW2')
        .attr('x', 330 + 32.7 * this.bridgePartCount + 100)
        .attr('y', 300)
        .attr('class', 'textLayer')
        .attr('class', 'hoverItem')
        // .on('click', function () { self.bridgeComponentClick('WW2') })
        .on('click', function () {
          self.bridgeComponentClickObject(
            'A',
            self.bridgePartIndex,
            'WW',
            2,
            'WW2',
            false,
            true
          )
          self.changeColor('WW2')
        })
      checkallow = await this.$store.dispatch('bridgeDetail/checkIsAllowed', {
        componentId: 'SP',
        inspectionType: this.inspectionType
      })
      g.append('text')
        .text('SP1')
        .attr('x', 400 + (32.7 * this.bridgePartCount) / 2 - 15)
        .attr('y', 400)
        .attr('class', 'textLayer')
        .attr('class', 'hoverItem')
        // .on('click', function () { self.bridgeComponentClick('WW2') })
        .on('click', function () {
          self.bridgeComponentClickObject(
            'A',
            self.bridgePartIndex,
            'SP',
            1,
            'SP1',
            false,
            true,
            checkallow
          )
          self.changeColor('SP1', checkallow)
        })
      if (this.isIndept) {
        styleColor = 'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
        for (let x = 0; x < this.indeptSectorComplete.FT.items.length; x++) {
          if (this.indeptSectorComplete.FT.items[x] === '1') {
            styleColor = 'fill:rgb(255,69,0);stroke-width:1;stroke:rgb(0,0,0)'
          }
        }
      } else {
        styleColor = 'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
      }
      checkallow = await this.$store.dispatch('bridgeDetail/checkIsAllowed', {
        componentId: 'FT',
        inspectionType: this.inspectionType
      })
      g.append('rect')
        .attr('id', function () {
          return 'FT1'
        })
        .attr('x', 300)
        .attr('y', 505)
        .attr('height', 30)
        .attr('width', 32.7 * this.bridgePartCount + 200)
        .attr('style', styleColor)
        .attr('class', checkallow ? 'hoverItem' : null)
        .on(
          'click',
          checkallow
            ? function () {
              self.bridgeComponentClickObject(
                'A',
                self.bridgePartIndex,
                'FT',
                1,
                'FT1',
                false,
                true
              )
              self.changeColor('FT1')
            }
            : null
        )
      g.append('text')
        .text('FT1')
        .attr('x', 400 + (32.7 * this.bridgePartCount) / 2 - 15)
        .attr('y', 525)
        .attr('class', 'textLayer')
        .attr('class', 'hoverItem')
        .on(
          'click',
          checkallow
            ? function () {
              self.bridgeComponentClickObject(
                'A',
                self.bridgePartIndex,
                'FT',
                1,
                'FT1',
                false,
                true
              )
              self.changeColor('FT1')
            }
            : null
        )
      g.append('text')
        .text('A' + this.bridgePartIndex.toString())
        .attr('x', 720)
        .attr('y', 100)
        .attr('opacity', opacity)
    },
    async drawPW (g, opacity) {
      const self = this
      let styleColor = 'fill:rgb(216,216,216);stroke-width:1;stroke:rgb(0,0,0)'
      const selectColor = 'fill:rgb(255,69,0);stroke-width:1;stroke:rgb(0,0,0)'
      if (this.isIndept) {
        styleColor = 'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
        for (let x = 0; x < this.indeptSectorComplete.PR.items.length; x++) {
          if (this.indeptSectorComplete.PR.items[x] === '1') {
            styleColor = selectColor
          }
        }
      } else {
        styleColor = 'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
      }
      g.append('rect')
        .attr('id', function () {
          return 'PR1'
        })
        .attr('x', 410)
        .attr('y', 305)
        .attr('height', 200)
        .attr('width', 32.7 * this.bridgePartCount - 20)
        .attr('style', styleColor)
        .attr('class', 'hoverItem')
        .on('click', function () {
          self.bridgeComponentClickObject(
            'P',
            self.bridgePartIndex,
            'PR',
            1,
            'PR1',
            false,
            true
          )
          self.changeColor('PR1')
        })
      if (this.isIndept) {
        styleColor = 'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
        for (let x = 0; x < this.indeptSectorComplete.FT.items.length; x++) {
          if (this.indeptSectorComplete.FT.items[x] === '1') {
            styleColor = selectColor
          }
        }
      } else {
        styleColor = 'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
      }
      const checkallow = await this.$store.dispatch(
        'bridgeDetail/checkIsAllowed',
        {
          componentId: 'FT',
          inspectionType: this.inspectionType
        }
      )
      g.append('rect')
        .attr('id', function () {
          return 'FT1'
        })
        .attr('x', 390)
        .attr('y', 505)
        .attr('height', 30)
        .attr('width', 32.7 * this.bridgePartCount + 20)
        .attr('style', styleColor)
        .attr('class', checkallow ? 'hoverItem' : null)
        .on(
          'click',
          checkallow
            ? function () {
              self.bridgeComponentClickObject(
                'P',
                self.bridgePartIndex,
                'FT',
                1,
                'FT1',
                false,
                true
              )
              self.changeColor('FT1')
            }
            : null
        )
      g.append('text')
        .text('FT1')
        .attr('x', 400 + (32.7 * this.bridgePartCount) / 2 - 15)
        .attr('y', 525)
        .attr('class', 'textLayer')
        .attr('class', 'hoverItem')
        .on('click', function () {
          self.bridgeComponentClickObject(
            'P',
            self.bridgePartIndex,
            'FT',
            1,
            'FT1',
            false,
            true,
            checkallow
          )
          self.changeColor('FT1', checkallow)
        })
      g.append('text')
        .text('P' + this.bridgePartIndex.toString())
        .attr('x', 720)
        .attr('y', 100)
        .attr('opacity', opacity)
      g.append('text')
        .text('PR1')
        .attr('x', 710)
        .attr('y', 410)
        .attr('class', 'textLayer')
        .attr('class', 'hoverItem')
        .on('click', function () {
          self.bridgeComponentClickObject(
            'P',
            self.bridgePartIndex,
            'PR',
            1,
            'PR1',
            false,
            true
          )
          self.changeColor('PR1')
        })
    },
    async drawPB (g, opacity) {
      const self = this
      let checkallow = false
      let styleColor = 'fill:rgb(216,216,216);stroke-width:1;stroke:rgb(0,0,0)'
      const selectColor = 'fill:rgb(255,69,0);stroke-width:1;stroke:rgb(0,0,0)'

      let footSpace = (32.7 * this.bridgePartCount) / this.bridgeFootCount / 2
      let bcSpace = 150 / this.bridgeBsCount / 2
      if (this.bridgeFootCount > 9) {
        footSpace = ((32.7 * this.bridgePartCount) / this.bridgeFootCount) * 1.5
        bcSpace = 150 / this.bridgeBsCount / 2
      }
      for (let i = 0; i < this.bridgeFootCount; i++) {
        if (i === 0) {
          if (this.isIndept) {
            styleColor =
              'fill:rgb(171,171,171);stroke-width:1;stroke:rgb(0,0,0)'
            for (
              let x = 0;
              x < this.indeptSectorComplete.PR.items.length;
              x++
            ) {
              if (this.indeptSectorComplete.PR.items[x] === '1') {
                styleColor = selectColor
              }
            }
          } else {
            styleColor =
              'fill:rgb(171,171,171);stroke-width:1;stroke:rgb(0,0,0)'
          }
          g.append('rect')
            .attr('id', function () {
              return 'PR' + (i + 1).toString()
            })
            .attr('x', 400 + footSpace * (i + 1) - 15)
            .attr('y', 305)
            .attr('height', 200)
            .attr('width', 30)
            .attr('style', styleColor)
            .attr('class', 'hoverItem')
            .on('click', function () {
              self.bridgeComponentClickObject(
                'P',
                self.bridgePartIndex,
                'PR',
                i + 1,
                'PR' + (i + 1).toString(),
                false,
                true
              )
              self.changeColor('PR' + (i + 1).toString())
            })
          if (this.isIndept) {
            styleColor =
              'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
            for (
              let x = 0;
              x < this.indeptSectorComplete.FT.items.length;
              x++
            ) {
              if (this.indeptSectorComplete.FT.items[x] === '1') {
                styleColor = selectColor
              }
            }
          } else {
            styleColor =
              'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
          }
          checkallow = await this.$store.dispatch(
            'bridgeDetail/checkIsAllowed',
            {
              componentId: 'FT',
              inspectionType: this.inspectionType
            }
          )
          console.log(checkallow)
          g.append('rect')
            .attr('id', function () {
              return 'FT' + (i + 1).toString()
            })
            .attr('x', 385 + footSpace * (i + 1) - 15)
            .attr('y', 505)
            .attr('height', 30)
            .attr('width', 60)
            .attr('style', styleColor)
            .attr('class', checkallow ? 'hoverItem' : null)
            .on(
              'click',
              checkallow
                ? function () {
                  self.bridgeComponentClickObject(
                    'P',
                    self.bridgePartIndex,
                    'FT',
                    i + 1,
                    'FT' + (i + 1).toString(),
                    false,
                    true
                  )
                  self.changeColor('FT' + (i + 1).toString())
                }
                : null
            )
        } else {
          if (this.isIndept) {
            styleColor =
              'fill:rgb(171,171,171);stroke-width:1;stroke:rgb(0,0,0)'
            for (
              let x = 0;
              x < this.indeptSectorComplete.PR.items.length;
              x++
            ) {
              if (
                this.indeptSectorComplete.PR.items[x] === (i + 1).toString()
              ) {
                styleColor = selectColor
              }
            }
          } else {
            styleColor =
              'fill:rgb(171,171,171);stroke-width:1;stroke:rgb(0,0,0)'
          }
          g.append('rect')
            .attr('id', function () {
              return 'PR' + (i + 1).toString()
            })
            .attr('x', 400 + footSpace * (i * 2 + 1) - 15)
            .attr('y', 305)
            .attr('height', 200)
            .attr('width', 30)
            .attr('style', styleColor)
            .attr('class', 'hoverItem')
            .on('click', function () {
              self.bridgeComponentClickObject(
                'P',
                self.bridgePartIndex,
                'PR',
                i + 1,
                'PR' + (i + 1).toString(),
                false,
                true
              )
              self.changeColor('PR' + (i + 1).toString())
            })
          if (this.isIndept) {
            styleColor =
              'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
            for (
              let x = 0;
              x < this.indeptSectorComplete.FT.items.length;
              x++
            ) {
              if (
                this.indeptSectorComplete.FT.items[x] === (i + 1).toString()
              ) {
                styleColor = selectColor
              }
            }
          } else {
            styleColor =
              'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
          }
          checkallow = await this.$store.dispatch(
            'bridgeDetail/checkIsAllowed',
            {
              componentId: 'FT',
              inspectionType: this.inspectionType
            }
          )
          console.log(checkallow)
          g.append('rect')
            .attr('id', function () {
              return 'FT' + (i + 1).toString()
            })
            .attr('x', 385 + footSpace * (i * 2 + 1) - 15)
            .attr('y', 505)
            .attr('height', 30)
            .attr('width', 60)
            .attr('style', styleColor)
            .attr('class', checkallow ? 'hoverItem' : null)
            .on(
              'click',
              checkallow
                ? function () {
                  self.bridgeComponentClickObject(
                    'P',
                    self.bridgePartIndex,
                    'FT',
                    i + 1,
                    'FT' + (i + 1).toString(),
                    false,
                    true
                  )
                  self.changeColor('FT' + (i + 1).toString())
                }
                : null
            )
          for (let j = 0; j < this.bridgeBsCount; j++) {
            if (this.isIndept) {
              styleColor =
                'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
              for (
                let x = 0;
                x < this.indeptSectorComplete.BC.items.length;
                x++
              ) {
                if (
                  this.indeptSectorComplete.BC.items[x] ===
                  (i + (j * self.bridgeFootCount - j)).toString()
                ) {
                  styleColor = selectColor
                }
              }
            } else {
              styleColor =
                'fill:rgb(230,230,230);stroke-width:1;stroke:rgb(0,0,0)'
            }
            g.append('rect')
              .attr('id', function () {
                return 'BC' + (i + (j * self.bridgeFootCount - j)).toString()
              })
              .attr('x', 400 + footSpace * (i * 2 - 1) + 15)
              .attr('y', 305 + (j * 2 + 1) * bcSpace)
              .attr('height', 20)
              .attr('width', footSpace * 2 - 30)
              .attr('style', styleColor)
              .attr('class', 'hoverItem')
              .on('click', function () {
                self.bridgeComponentClickObject(
                  'P',
                  self.bridgePartIndex,
                  'BC',
                  i + (j * self.bridgeFootCount - j),
                  'BC' + (i + (j * self.bridgeFootCount - j)).toString(),
                  false,
                  true
                )
                self.changeColor(
                  'BC' + (i + (j * self.bridgeFootCount - j)).toString()
                )
              })
            g.append('text')
              .text('BC' + (i + (j * this.bridgeFootCount - j)))
              .attr(
                'x',
                400 +
                  footSpace * (i * 2 - 1) -
                  10 +
                  (footSpace * 2 - 30) / 2 +
                  8
              )
              .attr('y', 305 + (j * 2 + 1) * bcSpace + 15)
              .attr('class', 'textLayer')
              .attr('class', 'hoverItem')
              .on('click', function () {
                self.bridgeComponentClickObject(
                  'P',
                  self.bridgePartIndex,
                  'BC',
                  i + (j * self.bridgeFootCount - j),
                  'BC' + (i + (j * self.bridgeFootCount - j)).toString(),
                  false,
                  true
                )
                self.changeColor(
                  'BC' + (i + (j * self.bridgeFootCount - j)).toString()
                )
              })
          }
        }
        g.append('text')
          .text('PR' + (i + 1).toString())
          .attr('x', 400 + footSpace * (i * 2 + 1) - 15)
          .attr('y', 480)
          .attr('class', 'textLayer')
          .attr('class', 'hoverItem')
          .on('click', function () {
            self.bridgeComponentClickObject(
              'P',
              self.bridgePartIndex,
              'PR',
              i + 1,
              'PR' + (i + 1).toString(),
              false,
              true
            )
            self.changeColor('PR' + (i + 1).toString())
          })
        checkallow = await this.$store.dispatch('bridgeDetail/checkIsAllowed', {
          componentId: 'FT',
          inspectionType: this.inspectionType
        })
        console.log(checkallow)
        g.append('text')
          .text('FT' + (i + 1).toString())
          .attr('x', 400 + footSpace * (i * 2 + 1) - 15)
          .attr('y', 525)
          .attr('class', 'textLayer')
          .attr('class', checkallow ? 'hoverItem' : null)
          .on(
            'click',
            checkallow
              ? function () {
                self.bridgeComponentClickObject(
                  'P',
                  self.bridgePartIndex,
                  'FT',
                  i + 1,
                  'FT' + (i + 1).toString(),
                  false,
                  true
                )
                self.changeColor('FT' + (i + 1).toString())
              }
              : null
          )
      }
      g.append('text')
        .text('P' + this.bridgePartIndex.toString())
        .attr('x', 400 + this.bridgeWidth / 2 - 15)
        .attr('y', 100)
        .attr('opacity', opacity)
    }
  },
  watch: {
    value () {
      this.valueInside = this.value
    },
    valueInside () {
      this.$emit('input', this.valueInside)
    }
  }
}
</script>
<style>
.hide {
  display: none;
}
.hoverItem {
  cursor: pointer;
}
</style>
<style scoped lang="scss">
img,
span {
  position: absolute;
}
.profile-view {
  // position: relative;
  height: 100%;
}
.spn-center {
  top: 180px;
}
.spn-joint {
  top: 4px;
}
.center.first.wing {
  left: 9px;
}
</style>
